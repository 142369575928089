<template>
	<div class="w-full max-w-lg mx-auto flex flex-col gap-4">
		<h2 class="text-lg font-black">
			Специфика компании
		</h2>
		<load-drop-down
			v-model="field.workers.value"
			:query="request.WORKERS"
			name="workers"
			label="Количество сотрудников"
			placeholder="Выберите количество сотрудников"
			:error="field.workers.isError"
			:message="field.workers.message"
			@on-select="changeWorkers"
		/>
		<load-drop-down
			v-model="field.specializations.value"
			:query="request.SPECIALIZATION"
			name="specializations"
			label="Основная специализация"
			placeholder="Выберите специализацию"
			:error="field.specializations.isError"
			:message="field.specializations.message"
			@on-select="changeSpecialization"
		/>
		<drop-down-input
			v-model="field.services.value"
			name="services"
			label="Услуги"
			placeholder="Можно выбрать несколько"
			:query="request.SERVICE"
			:error="field.services.isError"
			:message="field.services.message"
			@onСhoose="change"
		/>
		<drop-down-input
			v-model="field.channels.value"
			name="channels"
			label="Каналы и технологии"
			placeholder="Можно выбрать несколько"
			footnote="*Компания META признана экстремистской организацией и запрещена на территории РФ"
			:query="request.CHANNEL"
			:error="field.channels.isError"
			:message="field.channels.message"
			@onСhoose="change"
		/>

		<base-button
			type="submit"
			class="w-full"
			@click="toNextStep"
		>
			Отправить
		</base-button>
	</div>
</template>

<script>
import { submit } from "@/use/submit";
import { step3 } from "@/configForms/newCompany";
import { toRef } from "vue";
import LoadDropDown from "@/components/UI/LoadDropDown";
import DropDownInput from "@/components/DropDownInput/DropDownInput";
import { request } from "@/api/controllers";
import BaseButton from "@/components/UI/BaseButton";

export default {
	name: "Step3",
	components: { BaseButton, DropDownInput, LoadDropDown },
	setup() {
		const init = submit({
			fields: step3
		});
		
		const form = toRef(init, "form");
		const status = toRef(init, "status");
		return { form, status };
	},
	data() {
		return {
			request: request
		};
	},
	computed: {
		field() {
			return {
				...this.form.fields
			};
		}
	},
	methods: {
		toNextStep() {
			if (!this.form.valid) {
				this.form.showErrors()
				return;
			}
			this.$emit('finish', this.form.fields)
		},
		change(list, name) {
			this.field[name].value = [ ...list ];
		},
		changeWorkers(value) {
			this.field.workers.value = value;
		},
		changeSpecialization(value) {
			this.field.specializations.value = value;
		}
	}
}
</script>

<style scoped>

</style>
