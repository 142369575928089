const mainHandler = ({ errors, success, data }) => {
	if (success) {
		return { data, error: false }
	}

	if (errors.main) {
		return { error: true, errors }
	}
}

export default mainHandler;
