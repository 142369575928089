<template>
	<div class="w-full flex flex-wrap gap-2">
		<selected-item
			v-for="item in list"
			v-show="item.id !== 'no'"
			:id="item.id"
			:key="item.id"
			:title="item.name"
			:type="item.type"
			@click="$emit('remove', { id: item.id })"
		/>
	</div>
</template>

<script>
import SelectedItem from "@/components/UI/SelectedItem";
export default {
	name: "SelectedList",
	components: { SelectedItem },
	props: {
		list: {
			type: Array,
			required: true
		}
	}
};
</script>

<style scoped></style>
