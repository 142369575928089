<template>
	<confirm-modal
		title="Изменить пароль"
		:success="status.isSuccess"
		:error="status.isError"
		:pending="status.isPending"
		@close="closeModalPass"
	>
		<template #modal>
			<form
				class="max-w-[350px] w-full mx-auto flex flex-col gap-6"
				@submit.prevent="onSubmit"
			>
				<input-password
					v-model="oldPassword.value"
					label="Старый пароль"
					placeholder="Введите пароль"
					:error="oldPassword.touched && oldPassword.error"
					:message="oldPassword.message"
					@input="onInput('oldPassword')"
					@blur="onBlur('oldPassword')"
				/>
				<div class="-mt-4">
					<tool-tip class="left-[86px] top-[15px]">
						<div class="text-xs">
							Пароль должен состоять от 6 до 30 символов латинского алфавита, должен содержать минимум одну заглавную букву и одну цифру
						</div>
					</tool-tip>
					<input-password
						v-model="newPassword.value"
						label="Новый пароль"
						placeholder="Введите пароль"
						:error="newPassword.touched && newPassword.error"
						:message="newPassword.message"
						@blur="onBlurNewPassword('newPassword')"
						@input="onInput('newPassword')"
					/>
				</div>
				<input-password
					v-model="repeatPassword.value"
					label="Повторите пароль"
					placeholder="Введите пароль"
					:error="repeatPassword.touched && repeatPassword.error"
					:message="repeatPassword.message"
					@blur="onBlurNewPassword('repeatPassword')"
					@input="onInput('repeatPassword')"
				/>
				
				<base-button
					type="submit"
					class="w-full"
					:disabled="disabled"
				>
					Сохранить
				</base-button>
			</form>
		</template>
		<template #success>
			<p class="mt-8 mb-3">
				Пароль успешно изменен!
			</p>
		</template>
	</confirm-modal>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import InputPassword from "@/components/UI/InputPassword";
import ToolTip from "@/components/UI/ToolTip";
import { updatePassword } from "@/api/response";
import ConfirmModal from "@/components/Modal/ConfirmModal";
import { isValidPassword } from "@/helpers/validation";

const errorsMessage = {
	"The provided current password is invalid": "Указанный текущий пароль недействителен",
	"Your new password must be different than your current password":"Новый пароль не может совпадать со старым"
}

export default {
	name: "ChangePass",
	components: {
		BaseButton,
		InputPassword,
		ConfirmModal,
		ToolTip
	},
	emits: [ 'closeModalPass' ],
	data() {
		return {
			oldPassword: {
				value: "",
				touched: false,
				error: true,
				message: ""
			},
			newPassword: {
				value: "",
				touched: false,
				error: true,
				message: ""
			},
			repeatPassword: {
				value: "",
				touched: false,
				error: true,
				message: ""
			},
			status: {
				isPending: false,
				isError: false,
				isSuccess: false
			},
			message: "",
			disabled: true
		};
	},
	computed: {
		isSamePassword() {
			return this.newPassword.value === this.repeatPassword.value;
		},
		isValidForm() {
			return (
				!this.oldPassword.error &&
				!this.newPassword.error &&
				!this.repeatPassword.error
			);
		},
		isFilledNewPassword() {
			return this.newPassword.value !== "" && this.repeatPassword.value !== "";
		}
	},
	methods: {
		showErrors() {
			this.oldPassword.touched = true;
			this.newPassword.touched = true;
			this.repeatPassword.touched = true;
		},
		
		onSubmit() {
			if (!this.isValidForm) {
				this.showErrors();
				return;
			}
			this.status.isPending = true;
			
			const formatted = {
				currentPassword: this.oldPassword.value,
				password: this.newPassword.value,
				passwordConfirmation: this.repeatPassword.value
			};
			
			updatePassword(formatted).then(() => {
				this.status.isSuccess = true;
				this.status.isPending = false;
			}).catch(error => {
				console.log(error);
				this.oldPassword.error = true;
				this.oldPassword.message = errorsMessage[error.response.data.error.message];
				
				this.status.isSuccess = false;
				this.status.isPending = false;
				this.status.isError = true;
				
			});
		},
		
		onBlurNewPassword(type) {
			this.onBlur(type);
			const valid = isValidPassword(this[type].value)

			if (!valid) {
				this.disabled = true;
				this[type].error = true;
				this[type].message = "Недопустимые символы в пароле"
				return
			}

			if (this.isFilledNewPassword && valid) {
				this.newPassword.error = !this.isSamePassword;
				this.repeatPassword.error = !this.isSamePassword;
				this.disabled = !this.isSamePassword;
				this.newPassword.message = "Пароли должны совпадать";
				this.repeatPassword.message = "Пароли должны совпадать";
			}

			if (this.isFilledNewPassword && valid && this.isSamePassword) {
				this.disabled = false
			}
		},
		
		onBlur(type) {
			this[type].touched = true;
			this[type].error = this[type].value === "";
			this.oldPassword.error = false;
		},
		
		onInput(type) {
			this[type].touched = false;
		},
		
		closeModalPass() {
			return this.$emit("closeModalPass");
		}
	}
};
</script>
