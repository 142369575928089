<template>
	<confirm-modal
		:success="status.isSuccess"
		:error="status.isError"
		:pending="status.isPending"
		title="Запустить рабочую группу"
		@close="$emit('closeModalEdit')"
	>
		<template #modal>
			<form
				class="max-w-[270px] mx-auto flex flex-col gap-6"
				@submit.prevent="onSubmit"
			>
				<base-input
					v-model="field.comment.value"
					class="w-full"
					label="Оставьте сопроводительный комментарий"
					type="textarea"
					placeholder="Комментарий"
					:error="field.comment.isError"
					:message="field.comment.message"
					@blur="field.comment.blur"
					@input="field.comment.input"
				/>
				<base-button
					type="submit"
					class="w-full"
				>
					Отправить
				</base-button>
			</form>
		</template>
		<template #success>
			<p class="text-center">
				Заявка отправлена!
			</p>
		</template>
	</confirm-modal>
</template>

<script>
import ConfirmModal from "@/components/Modal/ConfirmModal";
import BaseInput from "@/components/UI/BaseInput";
import BaseButton from "@/components/UI/BaseButton";
import { submit } from "@/use/submit";
import { formGroup } from "@/api/response";
import group from "@/configForms/group";
import { toRef } from "vue";
import { actionGroup } from "@/handlers/constants";

export default {
	name: "OpenGroup",
	components: { ConfirmModal, BaseInput, BaseButton },
	props: {
		workingGroup: Object
	},
	setup() {
		const init = submit({
			submit: formGroup,
			fields: group()
		});
		
		const form = toRef(init, "form");
		const callback = toRef(init, "onSubmit");
		const status = toRef(init, "status");
		return { form, submit: callback, status };
		
	},
	
	computed: {
		field() {
			return { ...this.form.fields };
		}
	},
	
	methods: {
		onSubmit() {
			if (!this.form.valid) {
				this.form.showErrors();
				return;
			}
			
			this.field.action.value = actionGroup.START;
			this.field.workingGroup.value = this.workingGroup;
			this.submit();
		}
	}
};
</script>

<style scoped>

</style>