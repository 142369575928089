export const getErrors = (error, keys) => {
	let data = {}
	let message = 'Ошибка сервера, попробуйте еще раз';
	const errorList = error.response?.data?.error?.details?.errors;
	if (errorList) {
		data.fields = errorList.map(field => {
			const [ key ] = field.path;
			return { field: keys[key], message: field.message }
		})
		message = 'Ошибка валидации формы'
	}

	return Promise.reject({ message, data })
}

export const getErrorAuth = error => {
	let data = {}
	let message = 'Ошибка сервера, попробуйте еще раз';
	const errorList = error.response.data.error.details.errors;
	if (errorList) {
		data.fields = errorList[0]
		message = error.response.data.error.message
	}

	return Promise.reject({ message, data })
}