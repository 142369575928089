<template>
	<h2 class="text-xl">
		Успешная смена пароля
	</h2>
	<base-button @click="goAuth">
		Авторизоваться
	</base-button>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
export default {
	name: "PasswordRecoverySuccess",
	components: { BaseButton },
	methods: {
		goAuth() {
			this.$router.push("/auth")
		}
	}
}
</script>

<style scoped>

</style>