import routes from "@/router/routes";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	base: process.env.VUE_APP_PUBLIC_PATH
});

const isAuthenticated = () => store.getters.isAuth;

router.beforeEach((to, from, next) => {
	document.title = to.meta.title;
	next();
});

router.beforeEach((to, from, next) => {
	if (isAuthenticated() && (to.name === "auth" || to.name === "reset-password" || to.name === "new-password" || to.name === "registration" || to.name === "registration-company")) {
		return next({
			name: 'profile'
		})
	}

	next()
})

router.beforeEach((to, from, next) => {
	if (to.name === 'profile') {
		next()
	}

	if (isAuthenticated() && !store.getters.accessIsAllowed) {
		return next({
			name: 'profile'
		})
	}

	next()
})

export default router;
