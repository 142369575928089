<template>
	<confirm-modal
		title="Редактировать профиль"
		:success="status.success"
		:error="status.error"
		:show-error="status.error"
		:pending="status.pending"
		@close="$emit('closeModalEdit')"
	>
		<template #modal>
			<form
				class="max-w-[270px] mx-auto flex flex-col gap-6"
				@submit.prevent="onSubmit"
			>
				<div class="-mt-4">
					<tool-tip class="left-10 top-[15px]">
						<div class="text-xs">
							E-mail должен состоять только из латинских символов и цифр, содержать специальный символ "@". После символа "@" должна быть как минимум одна "."
						</div>
					</tool-tip>
					<input-email
						v-model="field.email.value"
						class="w-full"
						label="E-mail"
						placeholder="Введите почту"
						:error="field.email.isError"
						:message="field.email.message"
						@input="field.email.input"
						@blur="field.email.blur"
					/>
				</div>

				<input-tel
					v-model="field.phone.value"
					class="w-full"
					label="Телефон"
					placeholder="Введите номер"
					:error="field.phone.isError"
					:message="field.phone.message"
					@input="field.phone.input"
					@blur="field.phone.blur"
				/>
				
				<base-input
					v-model="field.name.value"
					class="w-full"
					label="Имя"
					type="text"
					placeholder="Введите имя"
					maxlength="32"
					:error="field.name.isError"
					:message="field.name.message"
					@blur="field.name.blur"
					@input="field.name.input"
				/>
				
				<base-input
					v-model="field.lastName.value"
					class="w-full"
					label="Фамилия"
					type="text"
					placeholder="Введите фамилию"
					maxlength="32"
					:error="field.lastName.isError"
					:message="field.lastName.message"
					@blur="field.lastName.blur"
					@input="field.lastName.input"
				/>
				
				<base-input
					v-model="field.post.value"
					class="w-full"
					label="Должность"
					type="text"
					placeholder="Введите должность"
					maxlength="32"
					:error="field.post.isError"
					:message="field.post.message"
					@blur="field.post.blur"
					@input="field.post.input"
				/>
				<base-button
					type="submit"
					class="w-full mb-6"
					:disabled="!isChanged"
				>
					Сохранить
				</base-button>
			</form>
		</template>
		<template #success>
			<p class="mt-8 mb-3">
				Данные успешно изменены!
			</p>
		</template>
		<template #error>
			{{ status.errorMessage }}
			<base-button @click="backToForm">
				Изменить email
			</base-button>
		</template>
	</confirm-modal>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import BaseInput from "@/components/UI/BaseInput";
import InputEmail from "@/components/UI/InputEmail";
import ConfirmModal from "@/components/Modal/ConfirmModal";
import ToolTip from "@/components/UI/ToolTip";
import { submit } from "@/use/submit";
import { toRef } from "vue";
import editPersonal from "@/configForms/editPersonal";
import { updateUser } from "@/api";
import { saveUserData } from "@/api/response";
import InputTel from "@/components/UI/InputTel";

export default {
	name: "EditProfile",
	components: { InputTel, BaseButton, BaseInput, InputEmail, ConfirmModal, ToolTip },
	emits: [ "closeModalEdit" ],
	setup() {
		const init = submit({
			fields: editPersonal()
		});
		
		const form = toRef(init, "form");
		return { form };
	},
	data() {
		return {
			status: {
				error: false,
				errorMessage: '',
				success: false,
				pending: false
			}
		}
	},
	computed: {
		field() {
			return { ...this.form.fields };
		},
		isChanged() {
			return Object.values(this.form.fields).some(item => item.changed);
		}
	},
	
	methods: {
		backToForm() {
			this.status.error = false
		},
		onSubmit() {
			if (!this.isChanged) return;
			
			if (!this.form.valid) {
				this.form.showErrors();
				return;
			}

			const data = {
				email: this.field.email.value,
				phone: this.field.phone.value,
				username: this.field.email.value,
				name: this.field.name.value,
				lastName: this.field.lastName.value,
				post: this.field.post.value
			}

			return updateUser(data)
				.then(response => {
					if (response.data.success === true) {
						saveUserData(response.data.data);
						this.status.success = true;
					} else {
						this.status.error = true;
						this.status.errorMessage = response.data.errors.list
					}

					return { ...response }
				})
				.catch(error => {
					return error
				});
		}
	}
};
</script>

<style scoped></style>
