<template>
	<div class="">
		<a
			href="https://arda.digital/"
			target="_blank"
			class="text-center"
		>
			<img
				src="@/assets/black_ARDA_logo.svg"
				alt="ARDA"
				class="max-w-xs w-full mx-auto sm:max-w-[150px]"
			>
		</a>
		<div
			class="w-full grid grid-cols-2 max-w-4xl mt-8 mb-4 sm:mt-4"
		>
			<div
				v-for="item in list"
				:key="item"
				class="text-center max-w-xs"
			>
				<div class="relative text-7xl md:text-5xl sm:text-2xl">
					<span class="absolute translate-x-0.5 translate-y-0.5">>{{ item.text }}</span>
					<span class="relative text-orange">>{{ item.text }}</span>
				</div>
				<p class="text-lg leading-4	md:text-xs">
					{{ item.heading }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AuthHeading",
	data() {
		return {
			list: [
				{
					heading: "городов РФ",
					text: "25"
				},
				{
					heading: "сотрудников в компаниях-участницах",
					text: "4000"
				}
			]
		};
	}
};
</script>

<style scoped>

</style>