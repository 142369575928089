<template>
	<records-filters @on-change="apply" />
	
	<div class="grid gap-6 grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 ">
		<card-video
			v-for="video of list"
			:key="video"
			:content="video"
		/>
	</div>
</template>

<script>
import CardVideo from "@/pages/RecordsPage/CardVideo";
import RecordsFilters from "@/pages/RecordsPage/RecordsFilters";
import { filter } from "@/use/filter";
import { filtersVideos } from "@/api/response";

export default {
	name: "RecordsContent",
	components: { CardVideo, RecordsFilters },
	props: {
		cards: {
			type: Array
		}
	},
	setup() {
		const { onSubmit, status, error } = filter(filtersVideos);
		
		return { onSubmit, status, error };
	},
	data() {
		return {
			list: this.cards
		}
	},
	methods: {
		apply(filters) {
			this.onSubmit(filters).then(response => {
				this.list = response
			})
		}
	}
};
</script>

<style scoped>

</style>