<template>
	<group-tags
		:name="name"
		:title="placeholder"
		:color="color"
		:variants="options"
		:save="saveFilters"
		:resettable="resettable"
		:filters="filters"
		@select="select"
		@remove="remove"
		@reset="reset"
	/>
</template>

<script>
import GroupTags from "@/components/UI/GroupTags";
import { mainHandler } from "@/handlers";
import { getDirectory } from "@/api";
import { loadData } from "@/use/load";
import { toRef } from "vue";

export default {
	name: "LoadTagsFilter",
	components: { GroupTags },
	props: {
		name: {
			type: String,
			required: true
		},
		query: {
			type: String,
			required: true
		},
		placeholder: {
			type: String
		},
		color: {
			type: String
		},
		saveFilters: {
			type: Boolean,
			default: false
		},
		resettable: {
			type: Boolean,
			default: false
		},
		filters: {
			type: Object
		}
	},
	emits: [ "onSelect" ],
	setup(props) {
		const request = () => getDirectory(props.query);
		const { data: response, status } = loadData({ query: request, handler: mainHandler, initialValue: [] });
		const data = toRef(response, "data");
		
		return {
			data,
			status
		};
	},
	computed: {
		options() {
			if (!this.data?.length) return [];
			
			let options = [ ...this.data ];
			
			return options || [];
		}
	},
	methods: {
		select({ ...obj }) {
			this.$emit("onSelect", { ...obj });
		},
		remove({ ...obj }) {
			this.$emit("onRemove", { ...obj });
		},
		reset() {
			this.$emit("onReset", this.name);
		}
	}
};
</script>

<style scoped>

</style>