import config from "@/router/config";

const configSymbol = "config";

export default {
	install(app) {
		app.config.globalProperties.$config = config;
		app.provide(configSymbol, config);
	}
};
