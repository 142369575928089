<template>
	<confirm-modal
		:pending="status.isPending"
		:error="status.isError"
		:success="status.isSuccess"
		title="Подтверждение логотипа"
		@close="$emit('closeModal')"
	>
		<template #modal>
			<div
				v-if="!showForm"
				class="text-center"
			>
				<p class="mb-4">
					Для возможности навигации по личному кабинету и просмотру всех доступных материалов вам нужно
					разместить логотип ARDA на своем ресурсе, после чего подтвердить свое действие. Файл с логотипом
					находится в письме, отправленном после модерации.
				</p>
				<base-button @click="toggleContent">
					Перейти к подтверждению
				</base-button>
			</div>
			<form
				v-if="showForm"
				class="w-full min-w-[400px] mx-auto"
				@submit.prevent="onSubmit"
			>
				<div class="-mt-4">
					<tool-tip class="left-12 top-[16px]">
						<div class="text-xs">
							Может содержать заглавные и строчные кириллические и латинские буквы и цифры. Должно содержать символ "."
						</div>
					</tool-tip>
					<base-input
						v-model="field.link.value"
						class="mb-8 w-full"
						label="Ссылка"
						type="text"
						placeholder="Вставьте сслыку на сайт"
						:error="field.link.isError"
						:message="field.link.message"
						@blur="field.link.blur"
						@input="field.link.input"
					/>
				</div>
				<base-button
					type="submit"
					class="w-full mb-6"
				>
					Отправить
				</base-button>
			</form>
		</template>
		<template #success>
			<p>
				Спасибо, что разместили наш логотип на вашем ресурсе! Полный доступ к личному кабинету ARDA откроется,
				как только модератор убедится в соблюдении всех требований. Ожидайте, пожалуйста, уведомление на ваш
				e-mail.
			</p>
		</template>
	</confirm-modal>
</template>

<script>
import ConfirmModal from "@/components/Modal/ConfirmModal";
import BaseInput from "@/components/UI/BaseInput";
import BaseButton from "@/components/UI/BaseButton";
import ToolTip from "@/components/UI/ToolTip";
import { submit } from "@/use/submit";
import { toRef } from "vue";
import logo from "@/configForms/logo";
import { confirmLogo } from "@/api/response";

export default {
	name: "ConfirmLogo",
	components: { ConfirmModal, BaseInput, BaseButton, ToolTip },
	setup() {
		const init = submit({
			submit: confirmLogo,
			fields: logo()
		});

		const form = toRef(init, "form");
		const callback = toRef(init, "onSubmit");
		const status = toRef(init, "status");

		return { form, submit: callback, status };
	},
	data() {
		return {
			showForm: false
		};
	},
	computed: {
		field() {
			return { ...this.form.fields };
		}
	},
	methods: {
		onSubmit() {
			if (!this.form.valid) {
				this.form.showErrors();
				return;
			}
			this.submit();
		},
		toggleContent() {
			this.showForm = true;
		}
	}
};
</script>

<style scoped>

</style>