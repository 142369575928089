<template>
	<div class="flex justify-between items-start">
		<div class="w-full flex flex-col justify-center gap-2 text-center">
			<a
				href="https://arda.digital/"
				target="_blank"
				class="text-center"
			>
				<img
					src="@/assets/black_ARDA_logo.svg"
					alt="ARDA"
					class="max-w-xs w-full mx-auto"
				>
			</a>
			<h2 class="text-lg font-black">
				Добавление дополнительного пользователя для вашей компании
			</h2>
			<p class="mx-auto max-w-2xl text-sm font-thin">
				Добавить пользователя можно только для компании, уже зарегистрированной в личном кабинете ARDA.
				Если ваша компания еще не прошла регистрацию — перейдите по
				<router-link
					:to="{ name: registration }"
					class="underline"
				>
					ссылке
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "RegistrationEmployeeHeader",
	data() {
		return {
			registration: this.$config.routes.registrationCompany.name
		}
	}
}
</script>

<style scoped>

</style>