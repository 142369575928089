<template>
	<div class="mb-8">
		<h2 class="sr-only">
			Steps
		</h2>
		
		<div class="after:-mt-3 after:block after:h-1 after:w-full after:rounded-lg after:bg-gray-200">
			<ol class="flex justify-between text-xs font-medium text-gray-400">
				<li
					v-for="(step, index) in steps"
					:key="step"
					class="flex flex-col items-center gap-2"
					:class="currentStep > index ? 'text-orange' : 'text-gray-400'"
				>
					<span class="">{{ step.title }}</span>
					
					<span
						class="rounded-full text-white w-5 h-5"
						:class="currentStep > index ? 'bg-orange' : undefined"
					>
						<svg
							v-if="currentStep > index"
							class="h-5 w-5"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fill-rule="evenodd"
								d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
								clip-rule="evenodd"
							/>
						</svg>
					</span>
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
export default {
	name: "ProgressBar",
	props: {
		currentStep: {
			type: Number,
			required: true
		},
		steps: {
			type: Array,
			required: true
		}
	}
}
</script>

<style scoped>

</style>
