<template>
	<drop-down
		v-model="value"
		:options="options"
		:label="label"
		:placeholder="placeholder"
		:name="name"
		class="w-full"
		:searchable="searchable"
		:error="error"
		:message="message"
		:is-loading="status.isPending"
		@on-select="$emit('onSelect', $event)"
	/>
</template>

<script>
import DropDown from "@/components/UI/DropDown";
import { REQUIRED_FIELD } from "@/handlers/constants";
import { toRef } from "vue";
import { loadData } from "@/use/load";
import { getDirectory } from "@/api";
import { mainHandler } from "@/handlers";

export default {
	name: "LoadDropDown",
	components: { DropDown },
	props: {
		modelValue: {
			type: Object,
			required: true
		},
		query: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			default: ""
		},
		label: {
			type: String
		},
		error: {
			type: Boolean,
			default: false
		},
		message: {
			type: String,
			default: REQUIRED_FIELD
		},
		searchable: {
			type: Boolean,
			default: false
		}
	},
	emits: [ "onSelect" ],
	setup(props) {
		const request = () => getDirectory(props.query);
		const { data: response, status } = loadData({ query: request, handler: mainHandler, initialValue: [] });
		const data = toRef(response, "data");

		return {
			data,
			status
		};
	},
	computed: {
		options() {
			if (!this.data?.length) return [];
			
			let options = this.sortOptions([ ...this.data ]);
			
			return options || [];
		},
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("onSelect", { ...value });
			}
		}
	},
	methods: {
		sortOptions(options) {
			return [ ...options ].sort((a, b) => {
				return a.name.localeCompare(b.name); 
			}).sort((a, b) => {
				return a.sorting - b.sorting;
			});
		},
	},
};
</script>

<style></style>
