<template>
	<confirm-modal
		title="Заявка для участия в рабочих группах"
		:success="status.isSuccess"
		:error="status.isError"
		:pending="status.isPending"
		@close="$emit('closeModalEdit')"
	>
		<template #modal>
			<form
				class="max-w-[300px] w-full mx-auto flex flex-col gap-6"
				@submit.prevent="onSubmit"
			>
				<base-input
					v-model="field.direction.value"
					class="w-full"
					label="Направление"
					type="text"
					placeholder="Предложите свое направление"
					:error="field.direction.isError"
					:message="field.direction.message"
					@blur="field.direction.blur"
					@input="field.direction.input"
				/>
				
				<base-input
					v-model="field.comment.value"
					class="w-full"
					label="Комментарий"
					type="textarea"
					placeholder="Оставьте сопроводительный комментарий"
					:error="field.comment.isError"
					:message="field.comment.message"
					@blur="field.comment.blur"
					@input="field.comment.input"
				/>
				
				<base-button
					type="submit"
					class="w-full mb-6"
				>
					Сохранить
				</base-button>
			</form>
		</template>
		<template #success>
			<p class="text-center">
				Заявка отправлена!
			</p>
		</template>
	</confirm-modal>
</template>

<script>
import ConfirmModal from "@/components/Modal/ConfirmModal";
import BaseButton from "@/components/UI/BaseButton";
import BaseInput from "@/components/UI/BaseInput";
import { submit } from "@/use/submit";
import { sendNewGroup } from "@/api/response";
import { toRef } from "vue";
import newCroup from "@/configForms/newCroup";
import { actionGroup } from "@/handlers/constants";

export default {
	name: "CreateGroup",
	components: { ConfirmModal, BaseButton, BaseInput },
	setup() {
		const init = submit({
			submit: sendNewGroup,
			fields: newCroup()
		});
		
		const form = toRef(init, "form");
		const callback = toRef(init, "onSubmit");
		const status = toRef(init, "status");
		return { form, submit: callback, status };
	},
	
	computed: {
		field() {
			return { ...this.form.fields };
		},
		isChanged() {
			return Object.values(this.form.fields).some(item => item.changed);
		}
	},
	
	methods: {
		onSubmit() {
			if (!this.form.valid) {
				this.form.showErrors();
				
				return;
			}
			
			this.field.action.value = actionGroup.CREATE_NEW;
			this.submit();
		}
	}
};
</script>

<style scoped>

</style>