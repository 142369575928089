<template>
	<div class="wrapper">
		<div class="content">
			<nav-bar-horizontal
				:show-menu="showMenu"
				@close-menu="closeMenu"
				@open-menu="openMenu"
			/>
			<div class="mx-auto max-w-[1920px] px-5 pb-8 mt-[65px] w-full">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import NavBarHorizontal from "@/components/Navigation/NavBarHorizontal";

export default {
	name: "LayoutPage",
	components: { NavBarHorizontal },
	data() {
		return {
			showMenu: false
		};
	},
	watch: {
		$route() {
			this.closeMenu();
		}
	},
	methods: {
		closeMenu() {
			this.showMenu = false;
		},
		openMenu() {
			this.showMenu = true;
		}
	}
};
</script>

<style scoped>
html,
body {
	height: 100%;
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	flex-grow: 1;
}

.content {
	flex: 1 0 auto;
}
</style>
