<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<div class="flex flex-wrap justify-between items-baseline gap-4 mb-4">
			<base-title v-if="data.title">
				{{ data.title }}
			</base-title>
			<base-button @click="toggleCreateModal">
				Запустить рабочую группу
			</base-button>
		</div>
		
		<base-description
			v-if="data.subTitle"
			class="data-styles whitespace-pre-line"
			v-html="markdownToHtml"
		/>
		
		<create-group
			v-if="showCreateModal"
			@closeModalEdit="toggleCreateModal"
		/>
	</page-loading>
</template>

<script>
import BaseTitle from "@/components/UI/BaseTitle";
import BaseDescription from "@/components/UI/BaseDescription";
import BaseButton from "@/components/UI/BaseButton";
import CreateGroup from "@/pages/GroupsPage/CreateGroup";
import PageLoading from "@/components/PageLoading/PageLoading";
import { loadData } from "@/use/load";
import { groupRulesPage } from "@/api";
import { mainHandler } from "@/handlers";
import { toRef } from "vue";
import { marked } from "marked";

export default {
	name: "CreationRulesGroupPage",
	components: { BaseDescription, BaseTitle, BaseButton, CreateGroup, PageLoading },
	setup() {
		const { data: response, status } = loadData({ query: groupRulesPage, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");
		
		return {
			data,
			status,
			errors
		};
	},
	data() {
		return {
			showCreateModal: false
		}
	},
	computed: {
		markdownToHtml() {
			return marked(this.data.subTitle);
		}
	},
	methods: {
		toggleCreateModal() {
			this.showCreateModal = !this.showCreateModal;
		}
	}
};
</script>

<style scoped>

</style>