<template>
	<nav
		class="flex justify-between items-center fixed left-0 top-0 z-40 w-screen max-h-[50px]
		 h-full bg-orange w-full p-3"
		@click.self="closeMenu"
	>
		<burger-button
			:show-menu="showMenu"
			@open-menu="openMenu"
			@close-menu="closeMenu"
		/>
		
		<div class="flex justify-between items-center gap-4">
			<main-link />
			<profile-link />
			<de-authorization />
		</div>
		
		<ul
			v-if="showMenu"
			class="border-2 border-black flex flex-col absolute bg-orange pt-7 z-40 left-0 top-[50px] max-w-2xl
			 h-full min-h-[calc(100vh-50px)] overflow-y-auto px-5 pb-14 md:max-w-none md:min-w-screen md:w-full
			  md:min-h-[calc(100vh-50px)]"
		>
			<nav-item
				v-for="(nav, index) of navs"
				:key="index + nav"
				:nav="nav"
			/>
			<nav-link
				v-for="(nav, index) of links"
				:key="index + nav"
				:nav="nav"
			/>
		</ul>
	</nav>

	<confirm-logo
		v-if="confirmModal"
		@closeModal="toggleConfirmLogo"
	/>
</template>

<script>
import NavItem from "@/components/Navigation/NavItem";
import NavLink from "@/components/Navigation/NavLink";
import store from "@/store";
import MainLink from "@/components/Navigation/MainLink";
import ProfileLink from "@/components/Navigation/ProfileLink";
import DeAuthorization from "@/components/Navigation/DeAuthorization";
import BurgerButton from "@/components/Navigation/BurgerButton";
import ConfirmLogo from "@/components/Navigation/ConfirmLogo";

export default {
	name: "NavBarHorizontal",
	components: { ConfirmLogo, BurgerButton, DeAuthorization, ProfileLink, MainLink, NavItem, NavLink },
	props: {
		showMenu: {
			type: Boolean
		}
	},
	emits: [ "closeMenu", "openMenu" ],
	data() {
		return {
			confirmModal: false,
			navs: [
				{
					title: "Видеозаписи",
					page: this.$config.routes.account.records.name
				},
				{
					title: "Общие документы",
					page: this.$config.routes.account.docs.name
				},
				{
					title: "Активности",
					page: this.$config.routes.account.activity.name
				},
				{
					title: "Чаты",
					page: this.$config.routes.account.chats.name
				},
				{
					title: "Рабочие группы",
					page: this.$config.routes.account.groups.name
				}
			],
			links: [
				{
					title: "Контакты",
					link: "https://arda.digital/"
				},
				{
					title: "Стандарты",
					link: "https://arda.digital/standards/"
				}
			]
		};
	},
	methods: {
		closeMenu() {
			return this.$emit("closeMenu");
		},
		
		toggleConfirmLogo() {
			this.confirmModal = !this.confirmModal
		},
		
		openMenu() {
			if (!store.getters.accessIsAllowed) {
				this.toggleConfirmLogo();
				return;
			}
			return this.$emit("openMenu");
		}
	}
};
</script>

<style scoped></style>
