<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<div class="flex flex-wrap justify-between items-baseline gap-4 mb-4">
			<base-title v-if="data.title">
				{{ data.title }}
			</base-title>
			<base-button @click="toggleNewEvent">
				Провести мероприятие
			</base-button>
		</div>
		<base-description
			v-if="data.subTitle"
			v-html="markdownToHtml"
		/>
		<base-header>
			{{ data.alert }} <br>
			<router-link
				:to="{ name: rules }"
				class="underline"
			>
				Как провести мероприятие
			</router-link>
		</base-header>
		<new-event
			v-if="showNewEvent"
			@closeModal="toggleNewEvent"
		/>
	</page-loading>
</template>

<script>
import PageLoading from "@/components/PageLoading/PageLoading";
import BaseTitle from "@/components/UI/BaseTitle";
import BaseDescription from "@/components/UI/BaseDescription";
import BaseHeader from "@/components/UI/BaseHeader";
import BaseButton from "@/components/UI/BaseButton";
import NewEvent from "@/pages/ApplicationEvent/NewEvent";
import { loadData } from "@/use/load";
import { eventHoldPage } from "@/api";
import { mainHandler } from "@/handlers";
import { toRef } from "vue";
import { marked } from "marked";

export default {
	name: "ApplicationEvent",
	components: { PageLoading, NewEvent, BaseTitle, BaseDescription, BaseHeader, BaseButton },
	setup() {
		const { data: response, status } = loadData({ query: eventHoldPage, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");

		return {
			data,
			status,
			errors
		};
	},
	data() {
		return {
			rules: this.$config.routes.account.rulesEvent.name,
			showNewEvent: false
		};
	},
	computed: {
		markdownToHtml() {
			return marked(this.data.subTitle);
		}
	},
	methods: {
		toggleNewEvent() {
			this.showNewEvent = !this.showNewEvent;
		}
	}
};
</script>

<style scoped>

</style>