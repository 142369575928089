import { axiosGet, axiosGetId, axiosPost } from "@/api/helpers";
import { API_REQUEST, request } from "@/api/controllers";
import axiosInstance from "@/plugins/axios";

export const mainPage = () => axiosGet(request.MAIN);
export const docsPage = () => axiosGet(request.DOCS_PAGE);
export const chatsPage = () => axiosGet(request.CHATS_PAGE);
export const videoPage = () => axiosGet(request.VIDEO_PAGE);
export const activityPage = () => axiosGet(request.ACTIVITY_PAGE);
export const workingGroupsPage = () => axiosGet(request.WORKING_GROUPS_PAGE);
export const groupRulesPage = () => axiosGet(request.GROUP_RULES_PAGE);
export const eventRulesPage = () => axiosGet(request.EVENT_RULES_PAGE);
export const eventHoldPage = () => axiosGet(request.EVENT_HOLD_PAGE);
export const createClusterPage = () => axiosGet(request.CREATE_CLUSTER_PAGE);
export const getCompanyUsers = () => axiosGet(request.USER_COMPANY)
export const getUser = data => axiosGet(request.GET_ME, data);

export const createEvent = data => axiosPost(request.EVENTS, data);
export const createGroup = data => axiosPost(request.WORKING_GROUPS_FORM, data);
export const createCluster = data => axiosPost(request.CLUSTER, data);
export const createCompany = data => axiosPost(request.COMPANY, data);
export const authQuery = data => axiosPost(request.AUTH, data);
export const confirmLogoQuery = data => axiosPost(request.CONFIRM_LOGO, data)
export const confirmCompanyQuery = data => axiosPost(request.CONFIRM_COMPANY, data)
export const updateCompany = data => axiosPost(request.COMPANY_EDIT, data)
export const sendEmailActivateAccount = data => axiosPost(request.ACTIVATE_ACCOUNT_EMAIL, data)
export const changePassword = data => axiosPost(request.CHANGE_PASS, data);
export const forgotPassword = data => axiosPost(request.FORGOT_PASS, data);
export const resetPassword = data => axiosPost(request.RESET_PASS, data);
export const createEmployee = data => axiosPost(request.USERS, data);

export const registrationCompany = data => axiosPost(request.REGISTER_COMPANY, data);
export const checkEmail = data => axiosPost(request.CHECK_EMAIL, data);
export const checkInn = data => axiosPost(request.CHECK_INN, data);

export const video = id => axiosGetId(request.VIDEO, id);
export const company = id => axiosGetId(request.COMPANY, id);

export const getDirectory = async query => {
	return axiosInstance.get(API_REQUEST[query])
}
export const getCardsVideos = async query => {
	return axiosInstance.get(API_REQUEST[request.VIDEO], {
		params: query
	})
}
export const getWorkingGroups = async query => {
	return axiosInstance.get(API_REQUEST[request.WORKING_GROUPS], {
		params: query
	})
}

export const register = async data => {
	return axiosInstance.post(API_REQUEST[request.REGISTER], data)
}

export const updateUser = async data => {
	return axiosInstance.patch(API_REQUEST[request.GET_ME], data)
}

export const changeEmployee = async (data, id) => {
	return axiosInstance.put(`${API_REQUEST[request.USERS]}/${id}`, data)
}

export const sendEmailToConfirmEmployee = async id => {
	return axiosInstance.post(`${API_REQUEST[request.SEND_EMPLOYEE_EMAIL_ACTIVATE]}/${id}`)
}
export const sendEmailToAdminForActivate = async id => {
	return axiosInstance.post(`${API_REQUEST[request.ACTIVATE_ACCOUNT_FOR_ADMIN_EMAIL]}/${id}`)
}
