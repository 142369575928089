<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<base-title v-if="data.data.name">
			{{ data.data.name }} {{ dateTitle }}
		</base-title>
		<div class="flex flex-col gap-4">
			<div class="flex flex-wrap gap-2">
				<div
					v-if="data.data.type"
					class="p-1 bg-lime text-sm"
				>
					{{ data.data.type.data.attributes.heading }}
				</div>
				<div
					v-for="direction of data.data.directions.data"
					:key="direction"
					class="p-1 bg-cyan text-sm"
				>
					{{ direction.attributes.heading }}
				</div>
			</div>
			<base-description
				v-if="data.data.description"
				class="max-w-[1250px]"
				v-html="data.data.description"
			/>
			<iframe
				width="100%"
				height="100%"
				:src="data.data.rutubeLink ? 'https://rutube.ru/play/embed/' + data.data.rutubeLink : 'https://www.youtube.com/embed/' + data.data.idYoutube"
				:title="data.data.title"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen
				class="min-h-[700px] max-w-[1250px] lg:min-h-[500px] md:min-h-[400px] sm:min-h-[300px]"
			/>
		</div>
		<button
			class="mt-4 flex gap-1 items-center text-gray-600"
			@click="back"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="w-4 h-4"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
				/>
			</svg>
			<span>
				Назад
			</span>
		</button>
	</page-loading>
</template>

<script>
import BaseTitle from "@/components/UI/BaseTitle";
import PageLoading from "@/components/PageLoading/PageLoading";
import BaseDescription from "@/components/UI/BaseDescription";
import { video } from "@/api";
import { computed, reactive, toRef } from "vue";
import { statusQuery } from "@/handlers/constants";
import router from "@/router";
import { formattedDate } from "@/helpers/formattedDate";

const initialValue = {};

export default {
	name: "VideoViewingPage",
	components: { BaseTitle, PageLoading, BaseDescription },
	setup() {
		const { id } = router.currentRoute.value.params;
		let status = reactive({ value: statusQuery.PENDING });
		let data = reactive(initialValue);
		let errors = { error: false, message: "error" };
		
		video(id)
			.then(response => {
				data.data = response.data;
				status.value = statusQuery.SUCCESS;
			})
			.catch(error => {
				console.log(error);
				status.value = statusQuery.ERROR;
				Object.entries(error).forEach(([ key, value ]) => {
					data.data[key] = value;
				});
			});
		
		const currentStatus = computed(() => ({
			isPending: status.value === statusQuery.PENDING,
			isError: status.value === statusQuery.ERROR,
			isSuccess: status.value === statusQuery.SUCCESS
		}));
		const attributes = toRef(data, "data");

		return { data: attributes, status: currentStatus, errors };
	},
	computed: {
		dateTitle() {
			if (this.data.data.date) return formattedDate(this.data.data.date)
		}
	},
	methods: {
		back() {
			this.$router.go(-1);
		}
	}
};
</script>

<style scoped>

</style>