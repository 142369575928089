<template>
	<button
		v-if="showMenu"
		class="w-auto flex justify-center align-middle"
		@click="$emit('closeMenu')"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			stroke-width="2"
			class="w-8 h-8"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M6 18L18 6M6 6l12 12"
			/>
		</svg>
	</button>

	<button
		v-if="!showMenu"
		type="button"
		class="w-auto flex justify-center align-middle"
		@click="$emit('openMenu')"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			stroke-width="2"
			class="w-8 h-8"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
			/>
		</svg>
	</button>
</template>

<script>
export default {
	name: "BurgerButton",
	props: {
		showMenu: {
			type: Boolean
		}
	},
	emits: [ "closeMenu", "openMenu" ]
}
</script>

<style scoped>

</style>