<template>
	<div class="flex flex-col h-screen justify-center items-center gap-6 p-6">
		<auth-heading />
		<router-view />
	</div>
</template>

<script>
import AuthHeading from "@/pages/AuthPage/AuthHeading";

export default {
	name: "AuthPage",
	components: {
		AuthHeading
	}
};
</script>

<style scoped>

</style>
