<template>
	<modal-template
		:title="title"
		@on-close="$emit('close')"
	>
		<slot
			v-if="success"
			name="success"
		/>
		<slot
			v-if="!success && !(showError && error)"
			name="modal"
		/>
		<slot
			v-if="showError && error"
			name="error"
		/>
	</modal-template>
</template>

<script>
import ModalTemplate from "@/components/Modal/ModalTemplate";

export default {
	name: "ConfirmModal",
	components: { ModalTemplate },
	props: {
		success: {
			type: Boolean,
			required: true
		},
		error: {
			type: Boolean,
			required: true
		},
		pending: {
			type: Boolean,
			required: true
		},
		showError: {
			type: Boolean,
			default: false
		},
		title: {
			type: String
		}
	},
	emits: [ "close" ]
};
</script>

<style scoped>

</style>
