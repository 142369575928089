<template>
	<base-input
		v-model="value"
		:type="passType"
		:placeholder="placeholder"
		:error="error"
		:label="label"
		:message="message"
		:maxlength="30"
		@blur="$emit('blur')"
		@input="$emit('input')"
	>
		<span
			v-if="passType === 'text'"
			class="absolute inset-y-0 right-4 cursor-pointer w-[20px] h-[20px] flex justify-center items-center m-auto text-2xl text-light-gray pointer-events-none"
		>/</span>
		<span
			class="absolute inset-y-0 inline-flex items-center right-4 cursor-pointer"
			@click="changePassType"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="w-5 h-5 text-gray-400"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
				/>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
				/>
			</svg>
		</span>
	</base-input>
</template>

<script>
import BaseInput from "@/components/UI/BaseInput";

export default {
	name: "InputPassword",
	components: { BaseInput },
	props: {
		modelValue: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: "text"
		},
		message: {
			type: String,
			default: () => {
			}
		},
		error: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			showPassword: false
		};
	},
	computed: {
		passType() {
			return this.showPassword ? "text" : "password";
		},
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				if (value.length >= 128) {
					console.log(value.length);
					return
				}
				this.$emit("update:modelValue", value);
			}
		}
	},
	methods: {
		changePassType() {
			this.showPassword = !this.showPassword;
		}
	}
};
</script>

<style scoped></style>
