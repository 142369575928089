import { INVALID_INN, INVALID_LINK, INVALID_NUMBER, REQUIRED_FIELD } from "@/handlers/constants";
import { isValidINN, isValidLink, isValidYear, required } from "@/helpers/validation";
import store from "@/store";

export default () => {
	const { name, inn, yearFoundation, site, cities, remote, format, workers, services, specializations, channels } = store.getters.company

	return {
		name: {
			value: name,
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		inn: {
			value: inn,
			validators: [
				{
					validator: isValidINN,
					message: INVALID_INN
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		yearFoundation: {
			value: yearFoundation,
			validators: [
				{
					validator: isValidYear,
					message: INVALID_NUMBER
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		site: {
			value: site,
			validators: [
				{
					validator: isValidLink,
					message: INVALID_LINK
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		cities: {
			value: cities,
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		newCity: {
			value: "",
			validators: []
		},
		format: {
			value: format,
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		workers: {
			value: workers,
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		services: {
			value: services,
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		specializations: {
			value: specializations,
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		channels: {
			value: channels,
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		remote: {
			value: remote,
		}
	};
}
