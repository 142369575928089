<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<div class="relative block w-full min-h-max h-full">
			<span class="absolute inset-0 translate-x-2 translate-y-2 bg-gray-50 shadow-md" />
			<div class="h-full flex flex-col justify-between gap-4 relative p-4 border-2 border-current">
				<div class="flex flex-row gap-2 items-center">
					<h3
						class="text-lg font-bold"
						@click="test"
					>
						Управление аккаунтами
					</h3>
					<help-user />
				</div>
				<div
					v-if="list.length"
					class="flex flex-col h-full w-full gap-4"
				>
					<div class="grid grid-cols-12 items-baseline gap-2">
						<h4 class="text-xs text-gray-400 col-span-5">
							Фамилия Имя
						</h4>
						<h4 class="text-xs text-gray-400 col-span-3">
							Роль
						</h4>
						<h4 class="text-xs text-gray-400 col-span-3">
							Статус
						</h4>
						<h4 class="text-xs text-gray-400 justify-self-center">
							Удалить
						</h4>
					</div>
					<div
						v-for="user of list"
						:key="user"
						class="grid grid-cols-12 items-center gap-2"
					>
						<p class="col-span-5">
							{{ user.name }} {{ user.lastName }}
						</p>
						<p class="col-span-3">
							{{ user.userRole === isEmployee ? isEmployee : "Администратор" }}
						</p>
						<div class="text-xs col-span-3">
							<span
								v-if="!user.activity"
								class="p-1 bg-pink cursor-pointer"
								@click="openModalActivateProfile(user)"
							>
								Не активен
							</span>
						</div>
						<icon-delete
							class="justify-self-center"
							@click="openModalDeleteProfile(user)"
						/>
					</div>
				</div>
				<div class="mt-auto flex flex-row lg:flex-col gap-4">
					<base-button @click="toggleModalCreate">
						Добавить сотрудника
					</base-button>
				</div>
			</div>
		</div>
	</page-loading>
	<modal-template
		v-if="modalActivateProfile"
		:title="`Активировать профиль пользователя ${name}?`"
		button-text="Активировать"
		@on-click="activateProfile"
		@on-close="closeModalActivateProfile"
	/>
	<modal-template
		v-if="modalDeleteProfile"
		:title="`Вы уверены, что хотите удалить сотрудника ${name}?`"
		button-text="Удалить"
		@on-click="acceptDeleteProfile"
		@on-close="closeModalDeleteProfile"
	/>
	<modal-template
		v-if="modalResult.view"
		:title="modalResult.title"
		@on-close="closeModalResult"
	/>
	<create-employee
		v-if="showCreate"
		@closeModalCreate="toggleModalCreate"
		@updateList="updateList"
	/>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import ModalTemplate from "@/components/Modal/ModalTemplate";
import IconDelete from "@/components/Icons/IconDelete";
import HelpUser from "@/pages/AccountPage/HelpUser";
import CreateEmployee from "@/pages/AccountPage/CreateEmployee";
import { toRef } from "vue";
import { changeEmployee, getCompanyUsers, sendEmailActivateAccount } from "@/api";
import { loadData } from "@/use/load";
import { mainHandler } from "@/handlers";
import PageLoading from "@/components/PageLoading/PageLoading";
import { USER_ROLE } from "@/handlers/constants";

export default {
	name: "UsersList",
	components: { PageLoading, BaseButton, ModalTemplate, IconDelete, HelpUser, CreateEmployee },
	setup() {
		const { data: response, status } = loadData({ query: getCompanyUsers, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");

		return {
			list: data,
			status,
			errors
		};
	},
	data() {
		return {
			modalActivateProfile: false,
			modalDeleteProfile: false,
			name: "",
			isEmployee: USER_ROLE.EMPLOYEE,
			selectedUser: null,
			showCreate: false,
			modalResult: {
				view: false,
				title: ''
			}
		};
	},

	methods: {
		test() {
			console.log(this.list);
		},
		openModalActivateProfile(user) {
			this.name = user.name;
			this.selectedUser = user;
			this.modalActivateProfile = !this.modalActivateProfile;
		},
		closeModalActivateProfile() {
			this.name = '';
			this.modalActivateProfile = !this.modalActivateProfile;
		},
		openModalDeleteProfile(user) {
			this.name = user.name;
			this.selectedUser = user;
			this.modalDeleteProfile = !this.modalDeleteProfile;
		},
		closeModalDeleteProfile() {
			this.name = '';
			this.modalDeleteProfile = !this.modalDeleteProfile;
			this.selectedUser = null
		},
		acceptDeleteProfile() {
			const user = { blocked: true }
			changeEmployee(user, this.selectedUser.id).then(() => {
				this.closeModalDeleteProfile();
				this.modalResult = {
					view: true,
					title: "Сотрудник удален"
				}
				return getCompanyUsers();
			}).then(response => {
				this.data = response.data.data;
			}).catch(err => {
				console.error(err)
			})
		},
		activateProfile() {
			const user = { activity: true, role: 1 }
			changeEmployee(user, this.selectedUser.id).then(() => {
				this.closeModalActivateProfile();
				this.modalResult = {
					view: true,
					title: "Сотрудник активирован"
				}
				return getCompanyUsers();
			}).then(response => {
				const body = { email: this.selectedUser.email }

				this.data = response.data.data;

				return sendEmailActivateAccount(body)
			}).then(() => {
				this.selectedUser = null;
			}).catch(err => {
				console.error(err)
			})
		},
		closeModalResult() {
			this.modalResult = {
				view: false,
				title: ''
			}
		},
		toggleModalCreate() {
			this.showCreate = !this.showCreate;
		},
		updateList() {
			getCompanyUsers().then(response => {
				return this.data = response.data.data;
			}).catch(() => {
				console.error(err)
			})
		}
	}
};
</script>

<style scoped>

</style>