import {
	INVALID_EMAIL,
	INVALID_INN,
	INVALID_LINK,
	INVALID_NUMBER,
	INVALID_PHONE,
	INVALID_STRING,
	REQUIRED_FIELD,
	REQUIRED_TELEGRAM
} from "@/handlers/constants";
import {
	isValidEmail,
	isValidINN,
	isValidLink,
	isValidPhone,
	isValidString,
	isValidYear,
	required
} from "@/helpers/validation";

export const step1 = {
	email: {
		value: "",
		validators: [
			{
				validator: isValidEmail,
				message: INVALID_EMAIL
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	phone: {
		value: "",
		validators: [
			{
				validator: isValidPhone,
				message: INVALID_PHONE
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	telegram: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_TELEGRAM
			}
		]
	},
	name: {
		value: "",
		validators: [
			{
				validator: isValidString,
				message: INVALID_STRING
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	lastName: {
		value: "",
		validators: [
			{
				validator: isValidString,
				message: INVALID_STRING
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	password: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	post: {
		value: "",
		validators: []
	}
};

export const step2 = {
	companyName: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	inn: {
		value: "",
		validators: [
			{
				validator: isValidINN,
				message: INVALID_INN
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	yearFoundation: {
		value: "",
		validators: [
			{
				validator: isValidYear,
				message: INVALID_NUMBER
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	site: {
		value: "",
		validators: [
			{
				validator: isValidLink,
				message: INVALID_LINK
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	cities: {
		value: [],
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	newCity: {
		value: "",
		validators: []
	},
	format: {
		value: [],
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	}, 
	remote: {
		value: false
	}
};

export const step3 = {
	workers: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	services: {
		value: [],
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	specializations: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	channels: {
		value: [],
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	}
}
