<template>
	<div class="mb-6 p-6 border-2 border-black">
		<div class="flex flex-wrap gap-6">
			<load-tags-filter
				:filters="filters"
				:name="filterList.EVENT_TYPE"
				:query="request.EVENT_TYPE"
				title="Тип мероприятия"
				color="lime"
				:save-filters="true"
				:resettable="true"
				@on-select="select"
				@on-remove="remove"
				@on-reset="resetFilters"
			/>
			<load-tags-filter
				:filters="filters"
				:name="filterList.DIRECTION"
				:query="request.DIRECTORY_DIRECTION"
				title="Направление"
				color="cyan"
				:save-filters="true"
				:resettable="true"
				@on-select="select"
				@on-remove="remove"
				@on-reset="resetFilters"
			/>
		</div>
	</div>
</template>

<script>
import LoadTagsFilter from "@/components/Filters/LoadTagsFilter";
import { request } from "@/api/controllers";
import { filterList } from "@/handlers/constants";

export default {
	name: "RecordsFilters",
	components: { LoadTagsFilter },
	data() {
		return {
			request: request,
			filters: {
				[filterList.EVENT_TYPE]: [],
				[filterList.DIRECTION]: []
			}
		};
	},
	computed: {
		filterList() {
			return filterList;
		}
	},
	mounted() {
		if (localStorage.filters) {
			this.filters = JSON.parse(localStorage.getItem("filters"))
			this.$emit('onChange', this.filters)
		}
	},
	methods: {
		select({ ...obj }) {
			if (!obj.listOf) return;
			
			if (this.filters[obj.listOf].find(i => i === obj.id) !== undefined) return;
			
			this.filters[obj.listOf].push(obj.id);
			localStorage.setItem('filters', JSON.stringify(this.filters))
			this.$emit('onChange', this.filters)
		},
		remove({ ...obj }) {
			this.filters[obj.listOf] = this.filters[obj.listOf].filter(item => item !== obj.id);
			localStorage.setItem('filters', JSON.stringify(this.filters))
			this.$emit('onChange', this.filters)
		},
		resetFilters(name) {
			this.filters[name] = [];
			localStorage.setItem('filters', JSON.stringify(this.filters))
			this.$emit('onChange', this.filters)
		}
	}
};
</script>

<style scoped>

</style>