<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		stroke-width="2"
		stroke="currentColor"
		class="w-6 h-6 cursor-pointer"
		@click="toggleShowInfo"
	>
		<path
			stroke-linecap="round"
			stroke-linejoin="round"
			d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
		/>
	</svg>
	<modal-template
		v-if="showInfo"
		title="Как добавить нового сотрудника"
		@on-close="toggleShowInfo"
	>
		Чтобы добавить нового сотрудника в систему, у вас есть два варианта. <br>
		Вы можете заполнить форму, которая появится после нажатия на кнопку "Добавить сотрудника", или отправить ссылку
		на форму человеку, которого вы хотите зарегистрировать. <br>
		В форме вам нужно будет указать основную информацию о сотруднике, такую как его имя, фамилию, электронную почту.<br>
		После заполнения формы и отправки ее в систему, новый сотрудник будет добавлен в базу данных, вам останеться
		лишь активировать его аккаунт.
		
		<div class="flex flex-row justify-between border border-2 p-2 border-black bg-orange-light">
			<input
				id="example"
				ref="message"
				v-model="message"
				class="w-5/6 outline-none bg-transparent"
				readonly
			>
			<button
				class=""
				@click="copyText"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="currentColor"
					class="w-6 h-6"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
					/>
				</svg>
			</button>
		</div>
	</modal-template>
</template>

<script>
import ModalTemplate from "@/components/Modal/ModalTemplate";

export default {
	name: "HelpUser",
	components: { ModalTemplate },
	data() {
		return {
			showInfo: false,
			message: `${process.env.VUE_APP_BASE_URL}registration`
		};
	},
	methods: {
		toggleShowInfo() {
			this.showInfo = !this.showInfo;
		},
		copyText() {
			const element = this.$refs.message;
			element.select();
			element.setSelectionRange(0, 99999);
			document.execCommand('copy');
		}
	}
};
</script>

<style scoped>

</style>