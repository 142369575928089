export const request = {
	MAIN: "main",
	DOCS_PAGE: "docs-page",
	CHATS_PAGE: "chats-page",
	VIDEO_PAGE: "video-page",
	ACTIVITY_PAGE: "page-activity",
	VIDEO: "videos",
	WORKING_GROUPS_PAGE: "working-groups-page",
	WORKING_GROUPS: "working-groups",
	WORKING_GROUPS_FORM: "form-working-groups",
	EVENTS: "events",
	EVENT_RULES_PAGE: "page-event-rules",
	GROUP_RULES_PAGE: "page-group-rules",
	EVENT_HOLD_PAGE: "page-hold-event",
	CREATE_CLUSTER_PAGE: "page-create-cluster",
	CLUSTER: "clusters",
	COMPANY: "companies",
	COMPANY_EDIT: "companies-edit",
	CITY: "city",
	WORKERS: "workers",
	FORMAT: "format",
	CROUP_DIRECTION: "group-directions",
	GROUP_STATUS: "status-groups",
	EVENT_TYPE: 'directory-type-events',
	DIRECTORY_DIRECTION: 'directory-direction-events',
	REGISTER: 'register',
	REGISTER_COMPANY: 'registration-company',
	CHECK_EMAIL: 'checkEmail',
	CHECK_INN: 'checkInn',
	CHANGE_PASS: 'changePass',
	FORGOT_PASS: 'forgotPass',
	RESET_PASS: 'resetPass',
	SERVICE: 'service',
	SPECIALIZATION: 'specialization',
	CHANNEL: 'channel',
	AUTH: "auth",
	CONFIRM_LOGO: "confirm-email",
	CONFIRM_COMPANY: "confirm-company",
	GET_ME: "get-me",
	USER_COMPANY: "company-users",
	USERS: "users",
	ACTIVATE_ACCOUNT_EMAIL: "activate-account",
	ACTIVATE_ACCOUNT_FOR_ADMIN_EMAIL: "activate-account-for-admin",
	SEND_EMPLOYEE_EMAIL_ACTIVATE: "send-email-to-confirm-employee"
};

export const API_REQUEST = {
	[request.REGISTER]: "users/register",
	[request.REGISTER_COMPANY]: "/registration-company",
	[request.CHECK_EMAIL]: "/check-email",
	[request.CHECK_INN]: "/check-inn",
	[request.CHANGE_PASS]: "/auth/change-password",
	[request.FORGOT_PASS]: "/auth/forgot-password",
	[request.RESET_PASS]: "/auth/reset-password",
	[request.MAIN]: "main",
	[request.DOCS_PAGE]: "docs-page",
	[request.CHATS_PAGE]: "chats-page",
	[request.VIDEO_PAGE]: "video-page",
	[request.ACTIVITY_PAGE]: "page-activity",
	[request.VIDEO]: "videos",
	[request.WORKING_GROUPS_PAGE]: "working-groups-page",
	[request.WORKING_GROUPS]: "working-groups",
	[request.WORKING_GROUPS_FORM]: "form-working-groups",
	[request.GROUP_RULES_PAGE]: "page-group-rules",
	[request.EVENTS]: "events",
	[request.EVENT_RULES_PAGE]: "page-event-rules",
	[request.EVENT_HOLD_PAGE]: "page-hold-event",
	[request.CREATE_CLUSTER_PAGE]: "page-create-cluster",
	[request.CLUSTER]: "clusters",
	[request.COMPANY]: "companies",
	[request.COMPANY_EDIT]: "form-edit-companies",
	[request.CITY]: "/cities",
	[request.WORKERS]: "/directory-workers",
	[request.FORMAT]: "/directory-formats",
	[request.CROUP_DIRECTION]: "/group-directions",
	[request.GROUP_STATUS]: "/status-groups",
	[request.EVENT_TYPE]: "/directory-type-events",
	[request.DIRECTORY_DIRECTION]: "/directory-direction-events",
	[request.SERVICE]: "/directory-services",
	[request.SPECIALIZATION]: "/directory-specializations",
	[request.CHANNEL]: "/directory-channels",
	[request.AUTH]: "users/verify",
	[request.CONFIRM_LOGO]: "/confirm-email",
	[request.CONFIRM_COMPANY]: "/confirm-company",
	[request.GET_ME]: "/users/me",
	[request.USER_COMPANY]: "/company-users",
	[request.USERS]: "/users",
	[request.ACTIVATE_ACCOUNT_EMAIL]: "/activate-account",
	[request.ACTIVATE_ACCOUNT_FOR_ADMIN_EMAIL]: "/activate-account-for-admin",
	[request.SEND_EMPLOYEE_EMAIL_ACTIVATE]: "/send-email-to-confirm-employee"
};
