import { isValidLink, required } from "@/helpers/validation";
import { INVALID_LINK, REQUIRED_FIELD } from "@/handlers/constants";

export default () => {
	return {
		link: {
			value: "",
			validators: [
				{
					validator: isValidLink,
					message: INVALID_LINK
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		}
	};
}
