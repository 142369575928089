<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<div class="flex flex-wrap justify-between items-baseline gap-4 mb-4">
			<base-title v-if="data.title">
				{{ data.title }}
			</base-title>
			<base-button @click="toggleNewEvent">
				Провести мероприятие
			</base-button>
		</div>
		<base-description
			v-if="data.subTitle"
			v-html="markdownToHtml"
		/>
		<new-event
			v-if="showNewEvent"
			@closeModal="toggleNewEvent"
		/>
	</page-loading>
</template>

<script>
import BaseTitle from "@/components/UI/BaseTitle";
import BaseButton from "@/components/UI/BaseButton";
import NewEvent from "@/pages/ApplicationEvent/NewEvent";
import PageLoading from "@/components/PageLoading/PageLoading";
import { loadData } from "@/use/load";
import { eventRulesPage } from "@/api";
import { mainHandler } from "@/handlers";
import { toRef } from "vue";
import { marked } from "marked";

export default {
	name: "CreationRulesEventPage",
	components: { BaseTitle, BaseButton, NewEvent, PageLoading },
	setup() {
		const { data: response, status } = loadData({ query: eventRulesPage, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");
		
		return {
			data,
			status,
			errors
		};
	},
	data() {
		return {
			showNewEvent: false
		};
	},
	computed: {
		markdownToHtml() {
			return marked(this.data.subTitle);
		}
	},
	methods: {
		toggleNewEvent() {
			this.showNewEvent = !this.showNewEvent;
		}
	}
};
</script>

<style scoped>

</style>