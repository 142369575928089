export const formattedForm = (data, map) => {
	return Object.entries(data).reduce((acc, [ key, value ]) => {
		const field = map[key];

		acc[field] = value.value;

		return acc;
	}, {});
};

export const formattedFilters = (filters, map) => {
	return Object.entries(filters).reduce((acc, [ key, value ]) => {
		if (value.length === 0) return acc;

		const serverKey = map[key];

		acc[serverKey] = {
			id: {
				'$eq': value
			}
		};
		acc.publishedAt = {
			'$notNull': true
		};

		return acc;
	}, {})
}