<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<base-title v-if="data.title">
			{{ data.title }}
		</base-title>
		<base-description
			v-if="data.subTitle"
			v-html="markdownToHtml"
		/>
		<records-content :cards="data.list" />
	</page-loading>
</template>

<script>
import BaseTitle from "@/components/UI/BaseTitle";
import BaseDescription from "@/components/UI/BaseDescription";
import PageLoading from "@/components/PageLoading/PageLoading";
import { loadData } from "@/use/load";
import { videoPage } from "@/api";
import { mainHandler } from "@/handlers";
import { toRef } from "vue";
import RecordsContent from "@/pages/RecordsPage/RecordsContent";
import { marked } from "marked";

export default {
	name: "RecordsPage",
	components: { RecordsContent, BaseTitle, BaseDescription, PageLoading },
	
	setup() {
		const { data: response, status } = loadData({ query: videoPage, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");
		
		return {
			data,
			status,
			errors
		};
	},
	computed: {
		markdownToHtml() {
			return marked(this.data.subTitle);
		}
	}
};
</script>

<style scoped>

</style>