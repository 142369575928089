<template>
	<div class="relative block group w-full mb-6">
		<span
			class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light"
		/>
		<span
			class="w-full bg-transparent relative inline-block px-5 py-3 text-sm text-center font-bold
			tracking-widest uppercase text-black border-2 border-current"
		>
			<slot />
		</span>
	</div>
</template>

<script>
export default {
	name: "BaseHeader"
};
</script>

<style scoped>

</style>