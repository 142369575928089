<template>
	<form
		action=""
		class="w-full max-w-sm mx-auto flex flex-col gap-6"
		@submit.prevent="onSubmit"
	>
		<h2 class="text-center text-lg font-black">
			Вход в личный кабинет участника ARDA
		</h2>
		<input-email
			v-model="field.login.value"
			label="E-mail"
			placeholder="Введите логин"
			:error="field.login.isError"
			:message="field.login.message"
			@input="field.login.input"
			@blur="field.login.blur"
		/>
		<input-password
			v-model="field.password.value"
			label="Пароль"
			placeholder="Введите пароль"
			:error="field.password.isError"
			:message="field.password.message"
			@input="field.password.input"
			@blur="field.password.blur"
		/>
		<div
			v-if="error"
			class="text-red whitespace-pre-wrap"
		>
			{{ error }}
		</div>
		<base-button type="submit">
			Войти
		</base-button>
	</form>
	<div class="w-full mx-auto flex flex-col justify-center gap-2">
		<div class="flex justify-center gap-2">
			<h3 class="text-gray-400">
				Забыли пароль?
			</h3>
			<button
				class="underline"
				@click="goChangePass"
			>
				Восстановить пароль
			</button>
		</div>
		<div class="flex justify-center gap-2">
			<h3 class="text-gray-400">
				Нет аккаунта?
			</h3>
			<button
				class="underline"
				@click="goRegistrationCompany"
			>
				Регистрация компании-участника ARDA
			</button>
		</div>
		<div class="flex justify-center gap-2">
			<button
				class="underline"
				@click="goRegistration"
			>
				Добавить пользователя для уже зарегистрированной компании
			</button>
		</div>
	</div>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import InputEmail from "@/components/UI/InputEmail";
import InputPassword from "@/components/UI/InputPassword";
import submitForm from "@/use/submitForm";
import auth from "@/configForms/auth";
import { toRef } from "vue";
import { authUser } from "@/api/response";

export default {
	name: "AuthForm",
	components: {
		BaseButton,
		InputEmail,
		InputPassword
	},
	setup() {
		const submit = submitForm(auth);
		
		const form = toRef(submit, "form");
		const responseError = toRef(submit, "responseError");

		return { form, responseError };
	},
	data() {
		return {
			isDirty: false,
			error: null
		};
	},
	computed: {
		field() {
			const { login, password } = this.form.fields;
			return {
				login,
				password
			};
		}
	},
	methods: {
		onSubmit() {
			this.error = null

			if (!this.form.valid) {
				this.form.showErrors();
				return;
			}

			authUser(this.form.fields).then(() => {
				this.$router.push({ name: "profile" });
			}).catch(error => {
				this.error = error.message
				this.responseError(error);
			});
		},
		goRegistration() {
			this.$router.push("/registration")
		},
		goRegistrationCompany() {
			this.$router.push("/registration-company")
		},
		goChangePass() {
			this.$router.push("/reset-password")
		}
	}
};
</script>

<style scoped>

</style>