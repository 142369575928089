<template>
	<confirm-modal
		title="Заявка на создание нового кластера"
		:success="status.isSuccess"
		:error="status.isError"
		:pending="status.isPending"
		@close="$emit('closeModal')"
	>
		<template #modal>
			<form
				class="max-w-[450px] w-full mx-auto flex flex-col gap-6"
				@submit.prevent="onSubmit"
			>
				<base-input
					v-model="field.name.value"
					class="w-full"
					label="Название"
					type="text"
					placeholder="Введите название нового кластера"
					:error="field.name.isError"
					:message="field.name.message"
					@blur="field.name.blur"
					@input="field.name.input"
				/>
				<base-input
					v-model="field.comment.value"
					class="w-full"
					label="Описание кластера"
					type="textarea"
					placeholder="Введите описание"
					:error="field.comment.isError"
					:message="field.comment.message"
					@blur="field.comment.blur"
					@input="field.comment.input"
				/>
				
				<base-button type="submit">
					Подать заявку
				</base-button>
			</form>
		</template>
		<template #success>
			<p class="text-center">
				Заявка отправлена!
			</p>
		</template>
	</confirm-modal>
</template>

<script>
import ConfirmModal from "@/components/Modal/ConfirmModal";
import BaseButton from "@/components/UI/BaseButton";
import BaseInput from "@/components/UI/BaseInput";
import { submit } from "@/use/submit";
import { sendNewCluster } from "@/api/response";
import createCluster from "@/configForms/createCluster";
import { toRef } from "vue";

export default {
	name: "FormNewCluster",
	components: { BaseButton, BaseInput, ConfirmModal },
	setup() {
		const init = submit({
			submit: sendNewCluster,
			fields: createCluster()
		});
		
		const form = toRef(init, "form");
		const callback = toRef(init, "onSubmit");
		const status = toRef(init, "status");
		return { form, submit: callback, status };
	},
	
	computed: {
		field() {
			return { ...this.form.fields };
		}
	},
	
	methods: {
		onSubmit() {
			if (!this.form.valid) {
				this.form.showErrors();
				return;
			}
			this.submit();
		}
	}
};
</script>

<style scoped>

</style>