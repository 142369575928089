<template>
	<div
		v-show="!error && !success"
		class="flex flex-col min-h-screen p-6 gap-6 justify-center md:justify-start"
	>
		<registration-employee-header />
		<form
			class="w-full max-w-[412px] mx-auto flex flex-col gap-4"
			@submit.prevent="onSubmit"
		>
			<div class="-mt-2">
				<tool-tip class="left-16 top-[15px]">
					<div class="text-sm">
						Вашей компании нет в списке?
						<router-link
							:to="{ name: registration }"
							class="underline"
						>
							Зарегистрировать
						</router-link>
					</div>
				</tool-tip>
				<load-drop-down
					v-model="field.company.value"
					:query="request.COMPANY"
					name="company"
					class="w-full"
					label="Компания"
					placeholder="Выберите свою компанию"
					:error="field.company.isError"
					:message="field.company.message"
					searchable
					@on-select="change"
				/>
			</div>
			
			<base-input
				v-model="field.name.value"
				class="w-full"
				label="Имя"
				type="text"
				placeholder="Введите имя"
				maxlength="32"
				:error="field.name.isError"
				:message="field.name.message"
				@input="field.name.input"
				@blur="field.name.blur"
			/>
			<base-input
				v-model="field.lastName.value"
				class="w-full"
				label="Фамилия"
				type="text"
				placeholder="Введите фамилию"
				maxlength="32"
				:error="field.lastName.isError"
				:message="field.lastName.message"
				@blur="field.lastName.blur"
				@input="field.lastName.input"
			/>
			<base-input
				v-model="field.post.value"
				class="w-full"
				label="Должость"
				type="text"
				placeholder="Введите должность"
				maxlength="32"
				:error="field.post.isError"
				:message="field.post.message"
				@blur="field.post.blur"
				@input="field.post.input"
			/>
			<div class="-mt-4">
				<tool-tip class="left-10 top-[15px]">
					<div class="text-xs">
						E-mail должен состоять только из латинских символов и цифр, содержать специальный символ "@". После символа "@" должна быть как минимум одна "."
					</div>
				</tool-tip>
				<input-email
					v-model="field.email.value"
					class="w-full"
					label="Почта"
					type="email"
					placeholder="Введите почту"
					:error="field.email.isError"
					:message="field.email.message"
					@input="field.email.input"
					@blur="field.email.blur"
				/>
			</div>

			<input-tel
				v-model="field.phone.value"
				class="w-full"
				label="Телефон"
				type="email"
				placeholder="Введите номер"
				:error="field.phone.isError"
				:message="field.phone.message"
				@input="field.phone.input"
				@blur="field.phone.blur"
			/>

			<create-password
				v-model="field.password.value"
				:empty="emptyPass"
				@set-pass="setPass"
			/>
			<base-button
				type="submit"
				class="w-full"
			>
				Добавить пользователя
			</base-button>
		</form>
		<div class="text-center">
			Если вашей компании нет в списке, то вы еще не зарегистрировали ее на портале.
			Зарегистрируйте компанию
			<router-link
				:to="{ name: registration }"
				class="underline"
			>
				по ссылке
			</router-link>
		</div>
	</div>

	<registration-employee-success v-show="success" />
		
	<registration-employee-error
		v-show="error"
		:message="errorMessage"
		@click="backToForm"
	/>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import BaseInput from "@/components/UI/BaseInput";
import InputEmail from "@/components/UI/InputEmail";
import CreatePassword from "@/components/CreatePassword";
import LoadDropDown from "@/components/UI/LoadDropDown";
import ToolTip from "@/components/UI/ToolTip";
import RegistrationEmployeeSuccess from "@/pages/RegistrationPage/RegEmployee/RegistrationEmployeeSuccess";
import RegistrationEmployeeError from "@/pages/RegistrationPage/RegEmployee/RegistrationEmployeeError";
import { toRef } from "vue";
import { submit } from "@/use/submit";
import mainRegistration from "@/configForms/mainRegistration";
import { request } from "@/api/controllers";
import { register, sendEmailToAdminForActivate } from "@/api";
import RegistrationEmployeeHeader from "@/pages/RegistrationPage/RegEmployee/RegistrationEmployeeHeader";
import InputTel from "@/components/UI/InputTel";

export default {
	name: "RegistrationPage",
	components: {
		InputTel,
		ToolTip,
		RegistrationEmployeeHeader,
		RegistrationEmployeeError,
		LoadDropDown,
		BaseButton,
		BaseInput,
		InputEmail,
		CreatePassword,
		RegistrationEmployeeSuccess
	},
	setup() {
		const init = submit({
			fields: mainRegistration
		});
		
		const form = toRef(init, "form");
		const callback = toRef(init, "onSubmit");
		const status = toRef(init, "status");
		return { form, submit: callback, status };
	},
	data() {
		return {
			success: false,
			error: false,
			emptyPass: false,
			errorMessage: '',
			request: request,
			registration: this.$config.routes.registrationCompany.name
		}
	},
	computed: {
		field() {
			return {
				...this.form.fields
			};
		}
	},
	methods: {
		onSubmit() {
			if (!this.form.valid) {
				this.form.showErrors();
				this.emptyPass = true

				return;
			}
			console.log(this.emptyPass)

			if (!!this.emptyPass) return;

			const data = {
				username: this.field.email.value,
				name: this.field.name.value,
				email: this.field.email.value,
				phone: this.field.phone.value,
				lastName: this.field.lastName.value,
				password: this.field.password.value,
				post: this.field.post.value,
				userRole: "Сотрудник",
				company: this.field.company.value
			};

			register(data).then(result => {
				return sendEmailToAdminForActivate(result.data.user.id)
			}).then(response => {
				this.success = true;
				return response
			}).catch(error => {
				this.error = true;
				this.errorMessage = error.response.data.error.message
			});
		},
		change(value) {
			this.field.company.value = value;
		},
		setPass(value) {
			if (!value) {
				this.emptyPass = true
				this.field.password.value = ''
				return
			}
			this.field.password.value = value
			this.emptyPass = false
		},
		backToForm() {
			this.error = false
		}
	}
};
</script>

<style scoped></style>
