import { isValidEmail, isValidPhone, isValidString, required } from "@/helpers/validation";
import { INVALID_EMAIL, INVALID_PHONE, INVALID_STRING, REQUIRED_FIELD } from "@/handlers/constants";
import store from "@/store";

export default () => {
	const { email, name, phone, lastName, post } = store.state.user

	return {
		email: {
			value: email,
			validators: [
				{
					validator: isValidEmail,
					message: INVALID_EMAIL
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]

		},
		phone: {
			value: phone,
			validators: [
				{
					validator: isValidPhone,
					message: INVALID_PHONE
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		name: {
			value: name,
			validators: [
				{
					validator: isValidString,
					message: INVALID_STRING
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]

		},
		lastName: {
			value: lastName,
			validators: [
				{
					validator: isValidString,
					message: INVALID_STRING
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]

		},
		post: {
			value: post,
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]

		}
	};
}
