<template>
	<div class="mb-6 p-6 border-2 border-black">
		<div class="flex flex-wrap gap-6">
			<load-tags-filter
				:filters="filters"
				:name="filterList.GROUP_STATUS"
				:query="request.GROUP_STATUS"
				title="Статус рабочей группы"
				@on-select="select"
				@on-remove="remove"
			/>
			<load-tags-filter
				:filters="filters"
				:name="filterList.DIRECTION"
				:query="request.CROUP_DIRECTION"
				title="Направление рабочей группы"
				color="green-pea"
				@on-select="select"
				@on-remove="remove"
			/>
		</div>
	</div>
</template>

<script>
import LoadTagsFilter from "@/components/Filters/LoadTagsFilter";
import { request } from "@/api/controllers";
import { filterList } from "@/handlers/constants";

export default {
	name: "GroupFilters",
	components: { LoadTagsFilter },
	data() {
		return {
			request: request,
			filters: {
				[filterList.GROUP_STATUS]: [],
				[filterList.DIRECTION]: []
			}
		};
	},
	computed: {
		filterList() {
			return filterList;
		}
	},
	methods: {
		select({ ...obj }) {
			if (!obj.listOf) return;

			if (this.filters[obj.listOf].find(i => i === obj.id) !== undefined) return;

			this.filters[obj.listOf].push(obj.id);
			this.$emit('onChange', this.filters)
		},
		remove({ ...obj }) {
			this.filters[obj.listOf] = this.filters[obj.listOf].filter(item => item !== obj.id);
			this.$emit('onChange', this.filters)
		}
	}
};
</script>

<style scoped>

</style>