<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<base-title v-if="data.title">
			{{ data.title }}
		</base-title>
		<div
			v-if="data.subTitle"
			class="data-styles whitespace-pre-line"
			v-html="markdownToHtml"
		/>
	</page-loading>
</template>

<script>
import { toRef } from "vue";
import { loadData } from "@/use/load";
import { mainPage } from "@/api";
import { mainHandler } from "@/handlers";
import PageLoading from "@/components/PageLoading/PageLoading";
import BaseTitle from "@/components/UI/BaseTitle";
import { marked } from "marked";

export default {
	name: "MainPage",
	components: { PageLoading, BaseTitle },
	setup() {
		const { data: response, status } = loadData({ query: mainPage, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");
		
		return {
			data,
			status,
			errors
		};
	},
	computed: {
		markdownToHtml() {
			return marked(this.data.subTitle);
		}
	}
};
</script>

<style>

</style>