import config from "@/router/config";
import LayoutPage from "@/components/layouts/LayoutPage";
import MainPage from "@/pages/MainPage/MainPage";
import AuthPage from "@/pages/AuthPage/AuthPage";
import ActivityPage from "@/pages/ActivityPage/ActivityPage";
import ChatsPage from "@/pages/ChatsPage/ChatsPage";
import DocsPage from "@/pages/DocsPage/DocsPage";
import GroupsPage from "@/pages/GroupsPage/GroupsPage";
import AccountPage from "@/pages/AccountPage/AccountPage";
import RecordsPage from "@/pages/RecordsPage/RecordsPage";
import VideoViewingPage from "@/pages/RecordsPage/VideoViewingPage";
import AuthForm from "@/pages/AuthPage/AuthForm";
import PasswordRecoveryEmail from "@/pages/AuthPage/PasswordRecoveryEmail";
import PasswordRecovery from "@/pages/AuthPage/PasswordRecovery";
import RegistrationPage from "@/pages/RegistrationPage/RegistrationPage";
import ApplicationEvent from "@/pages/ApplicationEvent/ApplicationEvent";
import CreationRulesGroupPage from "@/pages/GroupsPage/CreationRulesGroupPage";
import CreationRulesEventPage from "@/pages/ApplicationEvent/CreationRulesEventPage";
import NewCluster from "@/pages/ClusterPage/NewCluster";
import FormRegCompany from "@/pages/RegistrationPage/FormRegCompany";

export default [
	{
		path: "/",
		redirect: "/auth",
		name: config.routes.auth.name,
		pathToRegexpOptions: { strict: true },
		component: AuthPage,
		children: [
			{
				path: "/auth",
				name: config.routes.auth.name,
				component: AuthForm,
				meta: { title: "Авторизация" }
			},
			{
				path: "/reset-password",
				name: config.routes.resetPassword.name,
				component: PasswordRecoveryEmail,
				meta: { title: "Сброс пароля" }
			},
			{
				path: "/new-password",
				name: config.routes.newPassword.name,
				component: PasswordRecovery,
				meta: { title: "Сброс пароля" }
			}
		]
	},
	{
		path: "/registration",
		name: config.routes.registration.name,
		component: RegistrationPage,
		pathToRegexpOptions: { strict: true },
		meta: {
			title: "Регистрация"
		}
	},
	{
		path: "/registration-company",
		name: config.routes.registrationCompany.name,
		component: FormRegCompany,
		pathToRegexpOptions: { strict: true },
		meta: {
			title: "Регистрация"
		}
	},
	{
		path: "/portal",
		name: config.routes.account.name,
		component: LayoutPage,
		children: [
			{
				path: "/main",
				name: config.routes.account.main.name,
				component: MainPage,
				pathToRegexpOptions: { strict: true },
				meta: {
					title: "Главная страница"
				}
			},
			{
				path: "/profile",
				name: config.routes.account.profile.name,
				component: AccountPage,
				pathToRegexpOptions: { strict: true },
				meta: { title: "Личный кабинет" }
			},
			{
				path: "/records",
				name: config.routes.account.records.name,
				pathToRegexpOptions: { strict: true },
				component: RecordsPage,
				meta: {
					title: "Видеозаписи"
				}
			},
			{
				path: "/video/:id",
				name: config.routes.account.video.name,
				pathToRegexpOptions: { strict: true },
				component: VideoViewingPage,
				meta: {
					title: "Видеозаписи"
				}
			},
			{
				path: "/docs",
				name: config.routes.account.docs.name,
				pathToRegexpOptions: { strict: true },
				component: DocsPage,
				meta: {
					title: "Общие документы"
				}
			},
			{
				path: "/activity",
				name: config.routes.account.activity.name,
				pathToRegexpOptions: { strict: true },
				component: ActivityPage,
				meta: {
					title: "Что можно сделать на базе ARDA"
				}
			},
			{
				path: "/chats",
				name: config.routes.account.chats.name,
				pathToRegexpOptions: { strict: true },
				component: ChatsPage,
				meta: {
					title: "Чаты"
				}
			},
			{
				path: "/groups",
				name: config.routes.account.groups.name,
				pathToRegexpOptions: { strict: true },
				component: GroupsPage,
				meta: {
					title: "Рабочие группы"
				}
			},
			{
				path: "/groups-rules",
				name: config.routes.account.groupsRules.name,
				pathToRegexpOptions: { strict: true },
				component: CreationRulesGroupPage,
				meta: {
					title: "Рабочие группы"
				}
			},
			{
				path: "/new-cluster",
				name: config.routes.account.newCluster.name,
				pathToRegexpOptions: { strict: true },
				component: NewCluster,
				meta: {
					title: "Создать новый кластер"
				}
			},
			{
				path: "/application-event",
				name: config.routes.account.applicationEvent.name,
				pathToRegexpOptions: { strict: true },
				component: ApplicationEvent,
				meta: {
					title: "Заявка на участие в мероприятии"
				}
			},
			{
				path: "/rules-event",
				name: config.routes.account.rulesEvent.name,
				pathToRegexpOptions: { strict: true },
				component: CreationRulesEventPage,
				meta: {
					title: "Как провести мероприятие"
				}
			}
		]
	}
];
