<template>
	<a
		:href="link"
		:target="target ? '_blank' : '_self'"
		class="relative inline-block group focus:outline-none focus:ring text-center sm:w-full"
	>
		<span
			class="absolute inset-0 transition-transform
				translate-x-1.5 translate-y-1.5 bg-orange
				group-hover:translate-y-0 group-hover:translate-x-0"
		/>
		<span
			class="w-full relative inline-block px-8 py-3 text-sm
                            font-bold tracking-widest text-black uppercase border-2 border-current
                            group-active:text-opacity-75"
		>
			{{ text }}
		</span>
	</a>
</template>

<script>
export default {
	name: "StylizedLink",
	props: {
		text: {
			type: String,
			default: "Перейти"
		},
		link: {
			type: String,
			required: true
		},
		target: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style scoped>

</style>