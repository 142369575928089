<template>
	<div class="block w-full">
		<label
			class="relative w-full"
			:for="id"
		>
			<input
				:id="id"
				v-model="value"
				class="hidden group peer"
				type="checkbox"
				:value="id"
				:name="name"
			>
			<div class="relative inline-block group focus:outline-none focus:ring">
				<span
					class="absolute h-7 w-7 inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0"
				/>
				<span
					class="relative inline-block h-7 w-7 p-2 border-2 border-current"
				/>
			</div>
			
			<img
				class="absolute -top-2 left-1 h-5 w-5 hidden peer-checked:block"
				src="../../assets/checking-mark.png"
				alt=""
			>
			
			<slot />
		</label>
		<span
			v-if="error"
			class="mt-2 text-red block text-xs font-thin"
		>
			{{ textError }}
		</span>
	</div>
</template>

<script>
import { REQUIRED_FIELD } from "@/handlers/constants";

export default {
	name: "BaseCheckbox",
	props: {
		modelValue: {
			type: Boolean,
			required: true
		},
		id: {
			type: String,
			required: true
		},
		error: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			required: true
		},
		textError: {
			type: String,
			default: REQUIRED_FIELD
		}
	},
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("update", { value: value, id: this.id });
			}
		}
	}
};
</script>

<style scoped></style>
