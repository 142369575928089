<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<div class="flex flex-wrap justify-between items-baseline gap-4 mb-4">
			<base-title v-if="data.title">
				{{ data.title }}
			</base-title>
		</div>
		
		<base-header v-if="data.subTitle">
			{{ data.subTitle }}
		</base-header>
		<div class="grid grid-cols-4 gap-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mb-6">
			<activity-card
				v-for="link of data.activity"
				:key="link.id"
				:content="link"
			/>
		</div>
		<div class="text-center">
			Если у вас остались вопросы - напишите нам
			<a
				class="underline"
				href="#"
			>
				info@arda.ru
			</a>
		</div>
	</page-loading>
</template>

<script>
import BaseHeader from "@/components/UI/BaseHeader";
import BaseTitle from "@/components/UI/BaseTitle";
import ActivityCard from "@/pages/ActivityPage/ActivityCard";
import PageLoading from "@/components/PageLoading/PageLoading";
import { loadData } from "@/use/load";
import { activityPage } from "@/api";
import { mainHandler } from "@/handlers";
import { toRef } from "vue";

export default {
	name: "ActivityPage",
	components: { ActivityCard, BaseTitle, BaseHeader, PageLoading },
	setup() {
		const { data: response, status } = loadData({ query: activityPage, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");
		
		return {
			data,
			status,
			errors
		};
	},
	data() {
		return {
			links: [
				{ link: this.$config.routes.account.groups.name, title: "Стать участником рабочей группы по разработке стандартов" },
				{ link: this.$config.routes.account.groupsRules.name, title: "Организовать новую рабочую группу" },
				{ link: this.$config.routes.account.newCluster.name, title: "Создать новый кластер" },
				{ link: this.$config.routes.account.chats.name, title: "Присоединиться к IT-кластеру" },
				{ link: this.$config.routes.account.chats.name, title: "Присоединиться к чатам" },
				{ link: this.$config.routes.account.applicationEvent.name, title: "Провести мероприятие" },
				{ link: this.$config.routes.account.chats.name, title: "Получить стандарт" }
			]
		};
	}
};
</script>

<style scoped>

</style>