export const required = val => {
	if (Array.isArray(val) && val.length !== undefined) { // если массив, то валидируем его длинну
		return !!val.length
	}

	return !!val;
}

export const isValidEmail = email => {
	// eslint-disable-next-line no-useless-escape
	const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,9})$/;
	return reg.test(email) === true;
};

export const isValidPhone = phone => {
	// eslint-disable-next-line no-useless-escape
	const reg = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
	return reg.test(phone) === true;
};

export const isValidString = str => {
	// eslint-disable-next-line no-useless-escape
	const reg = /^[а-яА-ЯёЁa-zA-Z\-]+$/;
	return reg.test(str) === true;
};

export const isValidINN = inn => {
	const reg = /[^0-9]/;
	return !reg.test(inn);
};

export const isValidYear = year => {
	const reg = /^[0-9]+$/;
	return reg.test(year);
};

export const isValidLink = link => {
	const reg = /^((f|ht)tps?:\/\/)?([\wа-яё-]+\.)+[\wа-яё]+\/?$/i;
	return reg.test(link) === true;
}

export const isValidPassword = pass => {
	const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=[\]{},.<>/?;:'"\\|]{6,20}$/;
	return reg.test(pass) === true;
}

// ожидаю дату в формате dd.mm.yyyy
export const isValidDate = date => {
	return date.replace(/\s/g, "").length === 10;
};