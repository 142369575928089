<template>
	<div class="text-center m-auto">
		<h1 class="font-black text-4xl uppercase mb-10 lg:text-2xl lg:mb-5">
			Произошла ошибка при загрузке контента.
		</h1>
		<button
			class="relative inline-block group focus:outline-none focus:ring sm:w-full text-center"
			@click="$router.go()"
		>
			<span
				class="absolute inset-0 transition-transform translate-x-1.5
                 translate-y-1.5 bg-orange group-hover:translate-y-0 group-hover:translate-x-0"
			/>
			<span
				class="w-full relative inline-block px-8 py-3 text-sm
                 font-bold tracking-widest text-black uppercase border-2 border-current group-active:text-opacity-75"
			>
				Перезагрузить
			</span>
		</button>
	</div>
</template>

<script>

export default {
	name: "ErrorLoad"
};
</script>

<style scoped></style>
