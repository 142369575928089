<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<base-title v-if="data.title">
			{{ data.title }}
		</base-title>
		<base-description
			v-if="data.subTitle"
			class="data-styles whitespace-pre-line"
			v-html="markdownToHtml"
		/>
		<groups-content
			:cards="data.list"
			:message="errors?.list"
			:is-error="!!errors?.list"
		/>
	</page-loading>
</template>

<script>
import PageLoading from "@/components/PageLoading/PageLoading";
import BaseTitle from "@/components/UI/BaseTitle";
import BaseDescription from "@/components/UI/BaseDescription";
import GroupsContent from "@/pages/GroupsPage/GroupsContent";
import { loadData } from "@/use/load";
import { workingGroupsPage } from "@/api";
import { mainHandler } from "@/handlers";
import { toRef } from "vue";
import { marked } from "marked";

export default {
	name: "GroupsPage",
	components: { PageLoading, GroupsContent, BaseTitle, BaseDescription },
	setup() {
		const { data: response, status } = loadData({ query: workingGroupsPage, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");
		
		return {
			data,
			status,
			errors
		};
	},
	data() {
		return {
			showHeading: true
		}
	},
	computed: {
		markdownToHtml() {
			return marked(this.data.subTitle);
		}
	},
	methods: {
		toggleCreateModal() {
			this.showCreateModal = !this.showCreateModal;
		}
	}
};
</script>

<style></style>