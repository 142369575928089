import {
	authQuery, changePassword,
	confirmLogoQuery,
	createCluster,
	createEvent,
	createGroup,
	getCardsVideos,
	getWorkingGroups, updateCompany
} from "@/api/index";
import { getErrorAuth, getErrors } from "@/helpers/getErrors";
import { formattedFilters, formattedForm } from "@/api/formated";
import {
	authFields,
	fieldsEvent,
	fieldsGroupForms,
	fieldsNewCluster,
	fieldsNewGroup, fieldsUpdateCompany,
	filtersEvents,
	filtersGroups,
	KEYS
} from "@/handlers/constants";
import store from "@/store";
import { mailTemplate } from "@/helpers/mailTemplate";

export const saveUserData = user => {
	store.commit('getUser', user)
	localStorage.setItem(KEYS.USER, JSON.stringify(user));
}

export const changeInfoCompany = data => {
	const formatted = formattedForm(data, fieldsUpdateCompany);

	formatted.companyId = store.getters.companyId;
	formatted.companyName = store.getters.companyName;

	return updateCompany({ data: formatted }).then(response => {
		return { data: response.data }
	}).catch(error => {
		console.log(error);
		return getErrors(error)
	})
}

export const updatePassword = data => {
	return changePassword(data).then(response => {
		const jwt = data?.data?.jwt
		if (jwt) store.commit('getToken', jwt)
		return { data: response }
	}).catch(error => {
		return Promise.reject(error);
	})
};

export const authUser = data => {
	const formatted = formattedForm(data, authFields);

	return authQuery(formatted)
		.then(response => {
			const { user } = response.data

			saveUserData(user);
		}).catch(error => {
			console.log(error)
			return getErrorAuth(error)
		})
}

export const sendNewCluster = data => {
	const formatted = formattedForm(data, fieldsNewCluster);
	const mailBody = mailTemplate();

	formatted.user = mailBody.user;
	formatted.company = mailBody.company;

	return createCluster({ data: formatted }).then(response => {
		return { data: response.data }
	}).catch(error => {
		console.log(error);
		return getErrors(error, fieldsNewCluster)
	})
}

export const sendNewGroup = data => {
	const formatted = formattedForm(data, fieldsNewGroup);
	const mailBody = mailTemplate();

	formatted.user = mailBody.user;
	formatted.company = mailBody.company;

	return createGroup({ data: formatted }).then(response => {
		return { data: response.data }
	}).catch(error => {
		console.log(error);
		return getErrors(error, fieldsNewGroup)
	})
}

export const formGroup = data => {
	const formatted = formattedForm(data, fieldsGroupForms);
	const mailBody = mailTemplate();

	formatted.user = mailBody.user;
	formatted.company = mailBody.company;

	return createGroup({ data: formatted }).then(response => {
		return { data: response.data }
	}).catch(error => {
		console.log(error);
		return getErrors(error, fieldsGroupForms)
	})
}

export const confirmLogo = data => {
	const req = {
		link: data.link.value,
		company: {
			id: store.getters.companyId,
			name: store.getters.companyName
		}
	}
	console.log()
	return confirmLogoQuery(req).then(response => {
		return { data: response.data }
	}).catch(error => {
		return getErrors(error, { link: 'link', company: "company" })
	})
}

export const sendNewEvent = data => {
	const formatted = formattedForm(data, fieldsEvent);
	const mailBody = mailTemplate();

	formatted.user = mailBody.user;
	formatted.company = mailBody.company;

	return createEvent({ data: formatted }).then(response => {
		return { data: response.data }
	}).catch(error => {
		console.log(error);
		return getErrors(error, fieldsEvent)
	})
}

export const filtersVideos = query => {
	const formatted = formattedFilters(query, filtersEvents)
	return getCardsVideos({ filters: formatted }).then(response => {
		return { data: response.data }
	}).catch(error => {
		return getErrors(error, filtersEvents)
	})
}

export const filtersWorkingGroups = query => {
	const formatted = formattedFilters(query, filtersGroups)
	return getWorkingGroups({ filters: formatted }).then(response => {
		return { data: response.data }
	}).catch(error => {
		return getErrors(error, filtersGroups)
	})
}