<template>
	<div class="mb-6 relative inline-block w-full">
		<span class="absolute inset-0 translate-x-1.5 translate-y-1.5 bg-gray-50 shadow-md" />
		<div class="relative p-4 border-2 border-current">
			<h3 class="text-lg font-bold mb-4">
				Информация о пользователе
			</h3>
			
			<div class="w-full flex justify-between items-start gap-4 flex-wrap">
				<div class="w-full grid grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 sm:w-full gap-4">
					<div>
						<h4 class="text-xs text-gray-400">
							Фамилия
						</h4>
						<p class="text-lg leading-8">
							{{ user.lastName }}
						</p>
					</div>
					<div>
						<h4 class="text-xs text-gray-400">
							Имя
						</h4>
						<p class="text-lg leading-8">
							{{ user?.name }}
						</p>
					</div>
					<div>
						<h4 class="text-xs text-gray-400">
							Компания
						</h4>
						<p class="text-lg leading-8">
							{{ company }}
						</p>
					</div>
					<div>
						<h4 class="text-xs text-gray-400">
							Должность
						</h4>
						<p class="text-lg leading-8">
							{{ user.post }}
						</p>
					</div>
					<div>
						<h4 class="text-xs text-gray-400">
							Электронная почта
						</h4>
						<p class="text-lg leading-8">
							{{ user.email }}
						</p>
					</div>
					<div>
						<h4 class="text-xs text-gray-400">
							Телефон
						</h4>
						<p class="text-lg leading-8">
							{{ user.phone }}
						</p>
					</div>
				</div>
				<div class="flex flex-row lg:flex-col gap-4">
					<base-button @click="$emit('edit')">
						Редактировать профиль
					</base-button>
					<base-button @click="$emit('changePass')">
						Изменить пароль
					</base-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import { mapGetters } from "vuex";
import { getUser } from "@/api";

export default {
	name: "InfoUser",
	components: { BaseButton },
	setup() {
		getUser()
			.then(renounce => renounce)
			.catch(e => console.log(e))
	},
	computed: {
		...mapGetters({
			user: "user"
		}),
		company() {
			return this.$store.getters['companyName'];
		}
	}
};
</script>

<style scoped>

</style>
