<template>
	<base-title>
		Личный кабинет
	</base-title>
	<info-user
		@edit="toggleModalEdit"
		@changePass="toggleModalPass"
	/>
	<div class="grid grid-cols-2 lg:grid-cols-1 gap-6 mb-8">
		<info-company
			v-if="isAdmin"
			@editCompany="toggleModalEditCompany"
		/>
		<users-list
			v-if="isValidAdmin"
		/>
	</div>
	<edit-profile
		v-if="showEdit"
		@closeModalEdit="toggleModalEdit"
	/>
	<change-pass
		v-if="showChangePass"
		@closeModalPass="toggleModalPass"
	/>

	<edit-company
		v-if="showEditCompany"
		@close="toggleModalEditCompany"
	/>
</template>

<script>
import InfoUser from "@/pages/AccountPage/InfoUser";
import InfoCompany from "@/pages/AccountPage/InfoCompany";
import UsersList from "@/pages/AccountPage/UsersList";
import BaseTitle from "@/components/UI/BaseTitle";
import EditProfile from "@/pages/AccountPage/EditProfile";
import ChangePass from "@/pages/AccountPage/ChangePass";
import EditCompany from "@/pages/AccountPage/EditCompany";

export default {
	name: "AccountPage",
	components: { EditCompany, InfoUser, InfoCompany, UsersList, BaseTitle, EditProfile, ChangePass },
	data() {
		return {
			showEdit: false,
			showChangePass: false,
			showEditCompany: false,
			initialValue: null
		}
	},
	computed:{
		isAdmin() {
			return !this.$store.getters['isEmployee'];
		},
		isValidAdmin() {
			return this.$store.getters['isValidAdmin'];
		}
	},
	methods: {
		toggleModalEdit() {
			this.showEdit = !this.showEdit;
		},
		toggleModalPass() {
			this.showChangePass = !this.showChangePass;
		},

		toggleModalEditCompany() {
			this.showEditCompany = !this.showEditCompany;

			if (this.showEditCompany) {
				document.body.style.overflow = 'hidden'
			} else {
				document.body.removeAttribute('style')
			}
		}
	}
};
</script>

<style scoped>

</style>