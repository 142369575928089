import store from "@/store";

export const mailTemplate = () => {
	const body = {};
	body.user = {
		id: store.getters.user.id,
		name: store.getters.user.name,
		lastName: store.getters.user.lastName,
		email: store.getters.user.email
	}

	body.company = store.getters.companyId
    
	return body;
}