<template>
	<div
		class="w-full relative inline-block focus:outline-none focus:ring sm:w-full"
	>
		<span
			class="absolute w-full h-full inset-0 translate-x-2 translate-y-2 bg-gray-50 shadow-md inset-0
			 transition-transform group-hover:translate-y-0 group-hover:translate-x-0"
			:class="{'bg-orange-light': primary}"
		/>
		
		<div class="grid grid-flow-col sm:grid-flow-row auto-cols-auto grid-rows-1 gap-2 justify-around sm:justify-items-center items-center h-full w-full relative inline-block p-4 border-2 border-current">
			<img
				v-if="content.qrCode"
				:src="link + content.qrCode"
				class="max-w-[200px] block"
				:alt="content.title"
			>
			<div class="w-full flex flex-col gap-2 justify-center">
				<h3 class="flex-1 grow text-center uppercase font-bold">
					{{ content.name }}
				</h3>
				<p
					v-if="content.description"
					class="text-center"
				>
					{{ content.description }}
				</p>
				<div class="mx-auto mt-2">
					<stylized-link
						:link="content.link"
						target
						text="Присоединиться"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import StylizedLink from "@/components/UI/StylizedLink";

export default {
	name: "ChatsCard",
	components: { StylizedLink },
	props: {
		content: {
			type: Object
		},
		primary: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		link() {
			return process.env.VUE_APP_API_HOST;
		}
	}
};
</script>

<style scoped>

</style>