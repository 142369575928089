import { isValidEmail, isValidPhone, isValidString, required } from "@/helpers/validation";
import { INVALID_EMAIL, INVALID_PHONE, INVALID_STRING, REQUIRED_FIELD } from "@/handlers/constants";

export default {
	email: {
		value: "",
		validators: [
			{
				validator: isValidEmail,
				message: INVALID_EMAIL
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	phone: {
		value: "",
		validators: [
			{
				validator: isValidPhone,
				message: INVALID_PHONE
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	name: {
		value: "",
		validators: [
			{
				validator: isValidString,
				message: INVALID_STRING
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	lastName: {
		value: "",
		validators: [
			{
				validator: isValidString,
				message: INVALID_STRING
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	post: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	company: {
		value: null,
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	password: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	}
};
