<template>
	<button
		class="relative inline-block group focus:outline-none focus:ring sm:w-full disabled:opacity-50"
		@click="$emit('click')"
	>
		<span
			class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange group-hover:translate-y-0 group-hover:translate-x-0"
		/>
		<span
			class="w-full relative inline-block px-8 py-3
			 text-sm font-bold tracking-widest text-black uppercase
			 border-2 border-current group-active:text-opacity-75"
		>
			<slot />
		</span>
	</button>
</template>

<script>
export default {
	name: "BaseButton",
	emits: [ "click" ]
};
</script>

<style scoped>

</style>