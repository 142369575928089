<template>
	<div
		class="relative inline-block focus:outline-none focus:ring sm:w-full"
	>
		<span
			class="absolute w-full h-full inset-0 translate-x-2 translate-y-2 bg-gray-50 shadow-md inset-0
			 transition-transform"
		/>
		
		<div class="w-full h-full relative flex flex-col gap-4 justify-between p-4 border-2 border-current sm:p-4">
			<div class="flex flex-wrap gap-4">
				<group-status
					:status="content.status"
				/>
				<div
					v-if="content.direction"
					class="p-1 text-xs -ml-2 bg-green-pea"
				>
					{{ content.direction }}
				</div>
				<h3 class="w-full font-medium text-xl uppercase">
					{{ content.name }}
				</h3>
				<div class="-mt-2 font-medium leading-4">
					{{ content.type }}
				</div>
				<p class="whitespace-pre-line w-full" v-html="markdownToHtml(content.description)"></p>
			</div>
			<div class="flex sm:justify-between w-full flex-wrap gap-2">
				<base-button
					v-if="content.status === statusGroup.ACTIVE"
					:disabled="!content.possibilityJoin"
					@click="toggleModalJoin"
				>
					Присоединиться
				</base-button>
				<base-button
					v-if="content.status === statusGroup.SUSPENDED"
					@click="toggleModalReopen"
				>
					Возобновить
				</base-button>
				<base-button
					v-if="content.status === statusGroup.NOTSTARTED"
					@click="toggleModalOpen"
				>
					Запустить
				</base-button>
				<base-button
					v-if="content.status === statusGroup.COMPLETED"
					@click="toggleModalRestart"
				>
					Запустить
				</base-button>
			</div>
			<open-group
				v-if="showOpen"
				:working-group="content"
				@closeModalEdit="toggleModalOpen"
			/>
			<reopen-group
				v-if="showReopen"
				:working-group="content"
				@closeModalEdit="toggleModalReopen"
			/>
			<join-group
				v-if="showJoin"
				:working-group="content"
				@closeModalEdit="toggleModalJoin"
			/>
			<restart-group
				v-if="showRestart"
				:working-group="content"
				@closeModalEdit="toggleModalRestart"
			/>
		</div>
	</div>
</template>

<script>
import GroupStatus from "@/pages/GroupsPage/GroupStatus";
import BaseButton from "@/components/UI/BaseButton";
import JoinGroup from "@/pages/GroupsPage/JoinGroup";
import OpenGroup from "@/pages/GroupsPage/OpenGroup";
import ReopenGroup from "@/pages/GroupsPage/ReopenGroup";
import { statusGroup } from "@/handlers/constants";
import RestartGroup from "@/pages/GroupsPage/RestartGroup";
import { marked } from "marked";

export default {
	name: "GroupCard",
	components: { RestartGroup, GroupStatus, BaseButton, OpenGroup, JoinGroup, ReopenGroup },
	props: {
		content: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			statusGroup: statusGroup,
			showOpen: false,
			showReopen: false,
			showRestart: false,
			showJoin: false
		};
	},
	methods: {
		toggleModalOpen() {
			this.showOpen = !this.showOpen;
		},
		toggleModalReopen() {
			this.showReopen = !this.showReopen;
		},
		toggleModalJoin() {
			this.showJoin = !this.showJoin;
		},
		toggleModalRestart() {
			this.showRestart = !this.showRestart;
		},
		markdownToHtml(text) {
      		return marked(text || '');
    	},
	}
};
</script>

<style scoped></style>