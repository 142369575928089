<template>
	<div class="flex flex-col gap-2 max-w-xs">
		<h3 class="font-bold uppercase">
			Хотите преложить новую тему для рабочей группы?
		</h3>
		<router-link
			class="underline mb-1"
			:to="{ name: groupsRules }"
		>
			Как запустить рабочую группу?
		</router-link>
		<base-button
			class="max-w-xs"
			@click="$emit('create')"
		>
			Запустить группу
		</base-button>
	</div>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";

export default {
	name: "GroupNewTheme",
	components: { BaseButton },
	data() {
		return {
			groupsRules: this.$config.routes.account.groupsRules.name
		}
	}
};
</script>

<style scoped>

</style>