<template>
	<div
		class="relative min-h-[200px] inline-block focus:outline-none focus:ring sm:w-full"
	>
		<span
			class="absolute w-full h-full inset-0 translate-x-2 translate-y-2 bg-gray-50 shadow-md inset-0
			 transition-transform group-hover:translate-y-0
			 group-hover:translate-x-0"
		/>
		
		<div class="w-full h-full relative flex flex-col justify-between p-8 border-2 border-current sm:p-4">
			<div>
				<h3 class="uppercase mb-2">
					<strong>{{ content.name }}</strong>
				</h3>
				<p
					v-if="content.description"
					class="whitespace-pre-line line-clamp-1 text-base"
					v-html="markdownToHtml"
				/>
			</div>
			<div class="flex sm:justify-between w-full flex-wrap gap-2 mt-4">
				<stylized-link
					v-if="content.link"
					text="Ознакомиться"
					target
					:link="content.link"
					class=""
				/>
			</div>
		</div>
	</div>
</template>

<script>
import StylizedLink from "@/components/UI/StylizedLink";
import { marked } from "marked";

export default {
	name: "DocsCard",
	components: { StylizedLink },
	props: {
		content: {
			type: Object,
			required: true
		}
	},
	computed: {
		markdownToHtml() {
			return marked(this.content.description);
		}
	}
};
</script>

<style scoped>

</style>