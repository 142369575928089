<template>
	<div class="flex flex-wrap relative mb-1.5">
		<label
			v-if="label"
			for="email"
			class="w-full text-xs font-thin mb-1"
		>
			{{ label }}
		</label>
		<div class="relative block group w-full">
			<span
				class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0 group-focus:translate-y-0 group-focus:translate-x-0"
			/>
			<input
				v-if="type !== 'textarea'"
				id="text"
				v-model="value"
				:type="type"
				:name="name"
				class="w-full bg-transparent relative inline-block px-5 py-3 text-sm font-bold tracking-widest text-black border-2 border-current"
				:placeholder="placeholder"
				:maxlength="maxlength"
				autocomplete="on"
				@blur="$emit('blur')"
			>
			<textarea
				v-if="type === 'textarea'"
				v-model="value"
				class="w-full bg-transparent relative inline-block px-5 py-3 text-sm font-bold tracking-widest text-black border-2 border-current"
				:placeholder="placeholder"
			/>
			<slot />
		</div>
		<span
			v-if="error"
			class="absolute -bottom-6 text-red inline-block text-xs font-thin"
		>
			{{ message }}
		</span>
	</div>
</template>

<script>
import { REQUIRED_FIELD } from "@/handlers/constants";

export default {
	name: "BaseInput",
	props: {
		modelValue: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: false
		},
		type: {
			type: String,
			default: "text"
		},
		error: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			required: true
		},
		maxlength: {
			type: Number,
			required: false
		},
		message: {
			type: String,
			default: REQUIRED_FIELD
		},
		name: {
			type: String
		}
	},
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {

				this.$emit("update:modelValue", value.trim());
			}
		}
	}
};
</script>

<style scoped></style>
