<template>
	<router-link
		class="hover:bg-white p-1 rounded-full border-2 border-transparent hover:border-black"
		:to="{ name: mainLink }"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke-width="2"
			stroke="currentColor"
			class="w-6 h-6"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125
						 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125
						  1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
			/>
		</svg>
	</router-link>
</template>

<script>
export default {
	name: "MainLink",
	data() {
		return {
			mainLink: this.$config.routes.account.main.name
		}
	}
}
</script>

<style scoped>

</style>