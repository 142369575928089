import { reactive, computed, ref } from "vue";
import { statusQuery } from "@/handlers/constants";
import { useForm } from "@/use/form";

export function submit({ fields, submit }) {
	let status = reactive({ value: "" });
	let error = ref("");

	const form = useForm(fields);

	const responseError = data => {
		const fields = data?.data?.fields;
		if (fields?.length) {
			fields.forEach(({ field, message }) => {
				form.fields[field].valid = false;
				form.fields[field].touched = true;
				form.fields[field].message = message;
			});
			return;
		}
		error.value = data?.message || "Неизвестная ошибка сервера";
	};

	const onSubmit = () => {
		status.value = statusQuery.PENDING;

		submit(form.fields).then(() => {
			status.value = statusQuery.SUCCESS;
		}).catch(error => {
			status.value = statusQuery.ERROR;
			responseError(error);
		});
	};

	const currentStatus = computed(() => ({
		isPending: status.value === statusQuery.PENDING,
		isError: status.value === statusQuery.ERROR,
		isSuccess: status.value === statusQuery.SUCCESS
	}));

	return { form, onSubmit, status: currentStatus, error };
}
