<template>
	<div class="flex flex-wrap relative mb-1.5">
		<span
			v-if="label"
			class="w-full text-xs font-thin mb-1"
		>
			{{ label }}
		</span>
		<div class="relative block group w-full">
			<span
				class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0 group-focus:translate-y-0 group-focus:translate-x-0"
			/>
			<multiselect
				v-model="value"
				class="border-current border-2 multiselect-custom"
				:class="{'cursor-text':searchable}"
				:options="options"
				:searchable="searchable"
				:show-labels="true"
				select-label="+"
				deselect-label="-"
				selected-label=""
				:placeholder="placeholder"
				label="name"
				track-by="name"
			>
				<template #noOptions>
					<span class="text-sm font-bold tracking-widest text-black">Нет вариантов для выбора</span>
				</template>
				<template #noResult>
					<span class="text-sm font-bold tracking-widest text-black">Не найдено</span>
				</template>
			</multiselect>
		</div>
		<span
			v-if="error"
			class="absolute -bottom-6 text-red inline-block text-xs font-thin"
		>
			{{ message }}
		</span>
	</div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.css";
import Multiselect from "vue-multiselect";
import { REQUIRED_FIELD } from "@/handlers/constants";

export default {
	name: "DropDown",
	components: { Multiselect },
	props: {
		modelValue: {
			type: Object,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			required: true
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		label: {
			type: String
		},
		error: {
			type: Boolean,
			default: false
		},
		message: {
			type: String,
			default: REQUIRED_FIELD
		},
		searchable: {
			type: Boolean,
			default: false
		}
	},
	emits: [ "onSelect" ],
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("onSelect", { ...value });
			}
		}
	}
};
</script>

<style>
.multiselect-custom {
	cursor: pointer;
	box-sizing: border-box;
	color: black;
}

.multiselect--active {
	z-index: 30 !important;
}

.multiselect__spinner {
	background: transparent;
	width: 40px;
	height: 40px;
}

.multiselect__spinner:after, .multiselect__spinner:before {
	border-color: #fbb724 transparent transparent;
}

.multiselect-custom .multiselect__tags {
	min-height: inherit;
	padding: 10px 20px;
	border: none;
	border-radius: 0;
	font-size: 16px;
	line-height: 1.5;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background: transparent;
}

.multiselect__input, .multiselect__single {
	background: transparent;
	margin-bottom: 0;
}

.multiselect__single {
	display: block;
	padding: 2px 0;
	width: 100%;
	margin: 0;
	color: #000;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.1rem;
	font-weight: 700;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.multiselect-custom .multiselect__placeholder {
	display: block;
	padding: 2px 0;
	width: 100%;
	margin: 0;
	color: #9CA3AF;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	letter-spacing: 0.1rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background: transparent;
}

.multiselect__option {
	padding: 5px;
	min-height: inherit;
}

.multiselect-custom .multiselect__option--highlight {
	background: #F6E5CC;
	color: #000;
}

.multiselect-custom .multiselect__option--highlight::after {
	opacity: 0;
}

.multiselect-custom .multiselect__option--selected.multiselect__option--highlight {
	background: #F6E5CC;
	color: #000;
}

.multiselect-custom .multiselect__option--selected.multiselect__option--highlight::after {
	opacity: 0;
}

.multiselect__option--selected {
	background: #F6E5CC;
	color: #000;
}

.multiselect__content-wrapper {
	left: -2px;
	min-width: calc(100% + 4px);
	border-radius: 0;
	width: 100%;
	border: 2px solid #000000;
}

.multiselect__content-wrapper::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.multiselect--above .multiselect__content-wrapper {
	border: 2px solid #000000;
	border-radius: 0;
}

.multiselect__select {
	padding: 12px 20px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
}

.multiselect__select:before {
	display: block;
	position: initial;
	border-color: #000 transparent transparent transparent;
}

.multiselect__input {
	padding-left: 0;
}

.multiselect__input::placeholder {
	font-family: Roboto, sans-serif;
	color: #9ca3af;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 1.4px;
}
</style>
