<template>
	<h2 class="text-2xl font-bold mb-6">
		<slot />
	</h2>
</template>

<script>
export default {
	name: "BaseTitle"
};
</script>

<style scoped>

</style>
