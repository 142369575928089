export default {
	auth: {
		name: "auth"
	},
	resetPassword: {
		name: "reset-password"
	},
	newPassword: {
		name: "new-password"
	},
	profile: {
		name: "profile"
	},
	registration: {
		name: "registration"
	},
	registrationCompany: {
		name: "registration-company"
	},
	account: {
		name: "account",
		main: {
			name: "main"
		},
		profile: {
			name: "profile"
		},
		records: {
			name: "records"
		},
		video: {
			name: "video"
		},
		docs: {
			name: "docs"
		},
		activity: {
			name: "base"
		},
		chats: {
			name: "chats"
		},
		groups: {
			name: "groups"
		},
		allGroups: {
			name: "all-groups"
		},
		groupsRules: {
			name: "groups-rules"
		},
		newCluster: {
			name: "new-cluster"
		},
		applicationEvent: {
			name: "application-event"
		},
		rulesEvent: {
			name: "rules-event"
		}
	}
};
