<template>
	<div class="flex relative flex-col gap-2 mb-2">
		<multi-select
			v-model="value"
			:options="options"
			:name="name"
			:label="label"
			:list="list"
			:is-loading="status.isPending"
			:placeholder="placeholder"
			@on-select="onSelect"
			@on-remove="remove"
		/>
		<span
			v-if="error"
			class="absolute -bottom-6 text-red inline-block text-xs font-thin"
		>
			{{ message }}
		</span>
		<span
			v-if="footnote"
			class="text-xs text-gray-400 -mb-1"
		>
			{{ footnote }}
		</span>
		<base-input
			v-if="newOptionVisible"
			v-model="newOptionValue"
			class="w-full"
			placeholder="Добавить свой вариант"
			@blur="createNewOption"
		/>
		<base-checkbox 
			v-if="checkboxOption" 
			:model-value="checkboxActive" 
			class="group max-w-max"
			@update="selectCheckbox"
		>
			<span class="relative left-2 ml-0 -top-2 text-xs font-thin">
				{{ checkboxOptionText }}
			</span>
		</base-checkbox>
	</div>
</template>

<script>
import BaseInput from "@/components/UI/BaseInput";
import MultiSelect from "@/components/DropDownInput/MultiSelect";
import BaseCheckbox from "@/components/UI/BaseCheckbox";
import { loadData } from "@/use/load";
import { getDirectory } from "@/api";
import { toRef } from "vue";
import { mainHandler } from "@/handlers";
import { REQUIRED_FIELD } from "@/handlers/constants";

export default {
	name: "DropDownInput",
	components: { MultiSelect, BaseInput, BaseCheckbox },
	props: {
		modelValue: {
			type: Array,
			required: true
		},
		selectedItems: {
			type: Array,
			default: []
		},
		name: {
			type: String,
			required: true
		},
		moreOption: {
			type: Boolean,
			required: false,
			default: false
		},
		checkboxOption: {
			type: Boolean,
			default: false
		},
		checkboxActive: {
			type: Boolean,
			required: false,
			default: false
		},
		moreOptionTitle: {
			type: String,
			required: false
		},
		checkboxOptionText:{
			type: String,
			required: false
		},
		query: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		footnote: {
			type: String,
			required: false
		},
		error: {
			type: Boolean,
			default: false
		},
		message: {
			type: String,
			default: REQUIRED_FIELD
		}
	},
	emits: [ 'onSelect' ],
	setup(props) {
		const request = () => getDirectory(props.query)
		const { data: response, status } = loadData({ query: request, handler: mainHandler, initialValue: [] });
		const data = toRef(response, "data");
		return {
			data,
			status
		};
	},
	data() {
		return {
			list: this.selectedItems,
			showInput: false,
			newOption: null,
			newOptionVisible: false,
			newOptionValue: '',
			newOptionError: false,
			requiredError: REQUIRED_FIELD
		}
	},

	computed: {
		options() {
			if (!this.data?.length) return []
			
			const options = this.sortOptions([ ...this.data ]);
			
			if (this.moreOption) {
				options.push({ id: 'no', name: this.moreOptionTitle || 'Другое' });
			}
			
			return options || [];
		},
		listIds() {
			return this.list.map(i => i.id)
		},
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("onSelect", { value });
			}
		}
	},
	
	methods: {
		sortOptions(options) {
			return [ ...options ].sort((a, b) => {
				return a.name.localeCompare(b.name); 
			}).sort((a, b) => {
				return a.sorting - b.sorting;
			});
		},
		onSelect({ value }) {
			if (this.checkboxOption) {
				this.$emit("checkCheckbox");
			}

			const index = this.list.findIndex(i => i.id === value[0].id);

			if (index !== -1) return;

			const moreOptions = value.findIndex(i => i.id === "no") !== -1;
			if (moreOptions) {
				this.newOptionVisible = true;
			}

			const item = {
				name: value[0].name,
				id: value[0].id,
				key: value,
				type: "orange"
			}
			
			this.list = [ ...this.list, item ];

			this.$emit("onСhoose", this.listIds, this.name);
		},
		remove({ id }) {
			this.list = this.list.filter(i => i.id !== id);
			this.$emit("onСhoose", this.listIds, this.name);
		},
		createNewOption() {
			if (this.newOptionVisible && !this.newOptionValue && this.listIds.length === 1) {
				return;
			}
			
			this.$emit("crateNewOption", this.newOptionValue, this.name);
		},
		selectCheckbox() {
			this.list = [];
			this.listIds = [];
			this.newOptionVisible = false;
			this.newOptionValue = '';
			this.$emit("crateNewOption", this.newOptionValue, this.name);
			this.$emit("onСhoose", this.listIds, this.name);
			this.$emit("selectCheckbox");
		}
	}
}
</script>
