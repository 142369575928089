<template>
	<p
		v-if="isError"
		class="text-2xl text-center"
	>
		{{ message }}
	</p>

	<div class="mb-6">
		<group-filters @on-change="apply" />
		<div class="grid gap-6 mb-6 grid-cols-3 xl:grid-cols-2 sm:grid-cols-1">
			<group-card
				v-for="card of list"
				:key="card"
				:content="card"
			/>
		</div>
	</div>
	<group-new-theme @create="toggleCreateModal" />
	
	<create-group
		v-if="showCreateModal"
		@closeModalEdit="toggleCreateModal"
	/>
</template>

<script>
import GroupCard from "@/pages/GroupsPage/GroupCard";
import GroupFilters from "@/pages/GroupsPage/GroupFilters";
import GroupNewTheme from "@/pages/GroupsPage/GroupNewTheme";
import CreateGroup from "@/pages/GroupsPage/CreateGroup";
import { filter } from "@/use/filter";
import { filtersWorkingGroups } from "@/api/response";

export default {
	name: "GroupsContent",
	components: { GroupCard, GroupFilters, GroupNewTheme, CreateGroup },
	props: {
		message: {
			type: String,
			default: ''
		},
		isError: {
			type: Boolean,
			default: false
		},
		cards: {
			type: Array,
			default: () => []
		}
	},
	setup() {
		const { onSubmit, status, error } = filter(filtersWorkingGroups);
		
		return { onSubmit, status, error };
	},
	data() {
		return {
			selected: [],
			showCreateModal: false,
			query: null,
			list: this.cards
		};
	},
	methods: {
		apply(filters) {
			this.onSubmit(filters).then(response => {
				this.list = response
			})
		},
		toggleCreateModal() {
			this.showCreateModal = !this.showCreateModal;
		}
	}
};
</script>

<style scoped>

</style>