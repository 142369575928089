<template>
	<a
		:href="content.link"
		:target="content.targetBlank ? '_blank' : '_self'"
		class="relative min-h-[150px] inline-block group focus:outline-none focus:ring sm:w-full"
	>
		<span
			class="absolute w-full h-full inset-0 translate-x-2 translate-y-2 bg-gray-50 shadow-md inset-0
			 transition-transform group-hover:translate-y-0
			 group-hover:translate-x-0"
		/>
		
		<div
			class="flex flex-col justify-center items-center gap-1 h-full w-full relative inline-block px-8 py-4
                text-center border-2 border-current"
		>
			<span class="font-bold tracking-widest text-black uppercase">
				{{ content.name }}
			</span>
			<span class="text-sm">
				{{ content.description }}
			</span>
		</div>
	</a>
</template>

<script>
export default {
	name: "ActivityCard",
	props: {
		content: {
			type: Object
		}
	}
};
</script>

<style scoped>

</style>