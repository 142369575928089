<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<base-title v-if="data.title">
			{{ data.title }}
		</base-title>
		<base-description
			v-if="data.subTitle"
			v-html="markdownToHtml"
		/>
		<div class="grid gap-4 grid-cols-3 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 mb-6">
			<chats-card
				v-for="(chat, index) of data.list"
				:key="chat"
				:content="chat"
				:primary="index === 0"
			/>
		</div>
	</page-loading>
</template>

<script>
import BaseTitle from "@/components/UI/BaseTitle";
import ChatsCard from "@/pages/ChatsPage/ChatsCard";
import PageLoading from "@/components/PageLoading/PageLoading";
import BaseDescription from "@/components/UI/BaseDescription";
import { loadData } from "@/use/load";
import { chatsPage } from "@/api";
import { mainHandler } from "@/handlers";
import { toRef } from "vue";
import { marked } from "marked";

export default {
	name: "ChatsPage",
	components: { ChatsCard, BaseTitle, PageLoading, BaseDescription },
	setup() {
		const { data: response, status } = loadData({ query: chatsPage, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");
		
		return {
			data,
			status,
			errors
		};
	},
	computed: {
		markdownToHtml() {
			return marked(this.data.subTitle);
		}
	}
};
</script>

<style scoped>

</style>