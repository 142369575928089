<template>
	<div class="w-full max-w-lg mx-auto flex flex-col gap-4">
		<div class="flex flex-col gap-2 justify-between">
			<h2 class="text-lg font-black">
				Регистрация администратора личного кабинета
			</h2>
			<p class="mx-auto text-sm font-thin">
				Если вы еще не участник ARDA, то, заполняя эту форму, вы оставляете заявку на вступление в ассоциацию
			</p>
		</div>
		<base-input
			v-model="field.name.value"
			label="Имя"
			type="text"
			placeholder="Введите имя"
			maxlength="32"
			:error="field.name.isError"
			:message="field.name.message"
			@input="field.name.input"
			@blur="field.name.blur"
		/>
		<base-input
			v-model="field.lastName.value"
			label="Фамилия"
			type="text"
			placeholder="Введите фамилию"
			maxlength="32"
			:error="field.lastName.isError"
			:message="field.lastName.message"
			@blur="field.lastName.blur"
			@input="field.lastName.input"
		/>
		<base-input
			v-model="field.post.value"
			label="Должность"
			type="text"
			placeholder="Введите должность"
			maxlength="32"
			:error="field.post.isError"
			:message="field.post.message"
			@blur="field.post.blur"
			@input="field.post.input"
		/>
		<div class="-mt-4">
			<tool-tip class="left-10 top-[15px]">
				<div class="text-xs">
					E-mail должен состоять только из латинских символов и цифр, содержать специальный символ "@". После символа "@" должна быть как минимум одна "."
				</div>
			</tool-tip>
			<input-email
				v-model="field.email.value"
				label="Почта"
				type="email"
				placeholder="Введите почту"
				:error="field.email.isError"
				:message="field.email.message"
				@input="field.email.input"
				@blur="field.email.blur"
			/>
		</div>

		<input-tel
			v-model="field.phone.value"
			label="Телефон"
			type="email"
			placeholder="Введите номер"
			:error="field.phone.isError"
			:message="field.phone.message"
			@input="field.phone.input"
			@blur="field.phone.blur"
		/>
		<div class="-mt-4">
			<tool-tip class="left-[60px] top-[15px]">
				<div class="text-xs">
					Если не пользуетесь Телеграм - поставьте прочерк
				</div>
			</tool-tip>
			<base-input
				v-model="field.telegram.value"
				label="Телеграм"
				type="text"
				placeholder="Введите телеграм-ник"
				maxlength="50"
				:error="field.telegram.isError"
				:message="field.telegram.message"
				@blur="field.telegram.blur"
				@input="field.telegram.input"
			/>
		</div>

		<create-password
			v-model="field.password.value"
			:empty="emptyPass"
			@set-pass="setPass"
		/>
		<base-button
			class="w-full"
			@click="toNextStep"
		>
			Дальше
		</base-button>
	</div>
</template>

<script>
import BaseInput from "@/components/UI/BaseInput";
import InputEmail from "@/components/UI/InputEmail";
import BaseButton from "@/components/UI/BaseButton";
import CreatePassword from "@/components/CreatePassword";
import ToolTip from "@/components/UI/ToolTip";
import { submit } from "@/use/submit";
import { step1 } from "@/configForms/newCompany";
import { toRef } from "vue";
import { request } from "@/api/controllers";
import { checkEmail } from "@/api";
import InputTel from "@/components/UI/InputTel";

export default {
	name: "Step1",
	components: { InputTel, BaseInput, BaseButton, CreatePassword, InputEmail, ToolTip },
	emits: [ 'toNext' ],
	setup() {
		const init = submit({
			fields: step1
		});
		
		const form = toRef(init, "form");
		const status = toRef(init, "status");
		return { form, status };
	},
	data() {
		return {
			request: request,
			error: false,
			emptyPass: false
		};
	},
	
	computed: {
		field() {
			return {
				...this.form.fields
			};
		}
	},
	
	methods: {
		toNextStep() {
			if (!this.form.valid) {
				this.form.showErrors()
				this.emptyPass = true

				return;
			}

			if (!!this.emptyPass) return;

			const checkBody = {
				data: {
					email: this.field.email.value
				}
			}
			
			checkEmail(checkBody)
				.then(response => {
					this.$emit("toNext", this.form.fields)
					return response
				})
				.catch(e => {
					this.field.email.isError = true
					this.field.email.message = e.response.data.error.message
				})
		},
		setPass(value) {
			if (!value) {
				this.emptyPass = true
				this.field.password.value = ''
				return
			}
			this.field.password.value = value
			this.emptyPass = false
		}
	}
}
</script>

<style scoped>

</style>
