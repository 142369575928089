<template>
	<confirm-modal
		title="Добавить сотрудника"
		:success="status.isSuccess"
		:error="status.isError"
		:show-error="status.isError"
		@close="$emit('closeModalCreate')"
	>
		<template #modal>
			<form
				class="max-w-[270px] mx-auto flex flex-col gap-6"
				@submit.prevent="onSubmit"
			>
				<base-input
					v-model="field.name.value"
					class="w-full"
					label="Имя"
					type="text"
					placeholder="Введите имя"
					maxlength="32"
					:error="field.name.isError"
					:message="field.name.message"
					@blur="field.name.blur"
					@input="field.name.input"
				/>
				
				<base-input
					v-model="field.lastName.value"
					class="w-full"
					label="Фамилия"
					type="text"
					placeholder="Введите фамилию"
					maxlength="32"
					:error="field.lastName.isError"
					:message="field.lastName.message"
					@blur="field.lastName.blur"
					@input="field.lastName.input"
				/>

				<div class="-mt-4">
					<tool-tip class="left-10 top-[15px]">
						<div class="text-xs">
							E-mail должен состоять только из латинских символов и цифр, содержать специальный символ "@". После символа "@" должна быть как минимум одна "."
						</div>
					</tool-tip>
					<input-email
						v-model="field.email.value"
						class="w-full"
						label="E-mail"
						placeholder="Введите почту"
						:error="field.email.isError"
						:message="field.email.message"
						@input="field.email.input"
						@blur="field.email.blur"
					/>
				</div>
				
				<base-button
					type="submit"
					class="w-full"
					:disabled="!isChanged"
				>
					Добавить
				</base-button>
			</form>
		</template>
		<template #success>
			<p class="text-center">
				Сотрудник создан!
			</p>
		</template>
		<template #error>
			<div class="text-center flex flex-col justify-center gap-4">
				{{ errorMessage }}
				<base-button @click="showForm">
					Назад
				</base-button>
			</div>
		</template>
	</confirm-modal>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import BaseInput from "@/components/UI/BaseInput";
import InputEmail from "@/components/UI/InputEmail";
import ConfirmModal from "@/components/Modal/ConfirmModal";
import ToolTip from "@/components/UI/ToolTip";
import { submit } from "@/use/submit";
import createUser from "@/configForms/createUser";
import { toRef } from "vue";
import store from "@/store";
import { createEmployee, sendEmailToConfirmEmployee } from "@/api";
const errorsMessages = {
	"Email already taken": "Пользователь с такой почтой уже существует",
	"Username already taken": "Пользователь с такой почтой уже существует"
}
export default {
	name: "CreateEmployee",
	components: { BaseButton, BaseInput, ConfirmModal, InputEmail, ToolTip },
	emits: [ "closeModalCreate" ],
	setup() {
		const init = submit({
			fields: createUser()
		});
		
		const form = toRef(init, "form");
		return { form };
	},
	data() {
		return {
			status: {
				isError: false,
				isSuccess: false
			},
			errorMessage: ''
		}
	},
	computed: {
		field() {
			return { ...this.form.fields };
		},
		isChanged() {
			return Object.values(this.form.fields).some(item => item.changed);
		}
	},
	
	methods: {
		onSubmit() {
			if (!this.isChanged) return;
			
			if (!this.form.valid) {
				this.form.showErrors();
				return;
			}
			const req = {
				email: this.field.email.value,
				name: this.field.name.value,
				lastName: this.field.lastName.value,
				username: this.field.email.value,
				userRole: "Сотрудник",
				company: store.getters.companyId,
				password: this.field.email.value,
				role: 1,
				activity: true,
				confirmed: true
			}

			createEmployee(req).then(response => {
				this.status.isSuccess = true;
				this.status.isPending = false;
				return sendEmailToConfirmEmployee(response.data.id)
			}).then(() => {
				return this.$emit('updateList');
			}).catch(error => {
				this.status.isSuccess = false;
				this.status.isError = true;
				this.errorMessage = errorsMessages[error.response.data.error.message]
			})
		},
		showForm() {
			this.status.isSuccess = false;
			this.status.isError = false;
			this.errorMessage = '';
		}
	}
};
</script>

<style scoped>

</style>