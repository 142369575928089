<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<div class="relative block min-h-max w-full h-full">
			<span class="absolute inset-0 translate-x-2 translate-y-2 bg-gray-50 shadow-md" />
			<div class="h-full relative p-4 border-2 border-current">
				<h3 class="text-lg font-bold mb-4">
					Информация о компании
				</h3>
				<div class="mb-4">
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							Название компании
						</h4>
						<p class="col-span-3">
							{{ data.data.name }}
						</p>
					</div>
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							Сайт
						</h4>
						<a
							class="col-span-3 underline"
							:href="data.data.site"
						>
							{{ data.data.site }}
						</a>
					</div>
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							Формат сотрудничества
						</h4>
						<p class="col-span-3">
							{{ data.data.format.map((i)=> i.name).join(', ') }}
						</p>
					</div>
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							Год основания
						</h4>
						<p class="col-span-3">
							{{ data.data.yearFoundation }}
						</p>
					</div>
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							ИНН
						</h4>
						<p class="col-span-3">
							{{ data.data.inn }}
						</p>
					</div>
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							Город
						</h4>
						<div class="col-span-3">
							{{ data.data.remote ? 'Офиса нет, распределенная команда' : data.data.cities.map((i)=> i.name).join(', ') }}
						</div>
					</div>
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							Количество сотрудников
						</h4>
						<p class="col-span-3">
							{{ data.data.workers[0].name }}
						</p>
					</div>
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							Основная специализация
						</h4>
						<div class="col-span-3">
							{{ data.data.specializations[0].name }}
						</div>
					</div>
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							Услуги
						</h4>
						<p class="col-span-3">
							{{ data.data.services.map((i)=> i.name).join(', ') }}
						</p>
					</div>
					<div class="grid grid-cols-4 items-baseline gap-2">
						<h4 class="text-xs text-gray-400">
							Каналы и технологии
						</h4>
						<p class="col-span-3">
							{{ data.data.channels.map((i)=> i.name).join(', ') }}
						</p>
					</div>
				</div>
			
				<div class="flex flex-row lg:flex-col gap-4">
					<base-button @click="editInfo">
						Изменить данные
					</base-button>
				</div>
			</div>
		</div>
	</page-loading>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import PageLoading from "@/components/PageLoading/PageLoading";
import { computed, reactive, toRef } from "vue";
import { statusQuery } from "@/handlers/constants";
import { company } from "@/api";
import store from "@/store";

export default {
	name: "InfoCompany",
	components: { BaseButton, PageLoading },
	emits: [ 'editCompany' ],
	setup() {
		const id = store.getters['companyId'];
		let status = reactive({ value: statusQuery.PENDING });
		let data = reactive({});
		let errors = { error: false, message: "error" };

		company(id)
			.then(response => {
				data.data = response.data;
				status.value = statusQuery.SUCCESS;
				store.commit('getCompany', response.data.data)
			})
			.catch(error => {
				console.log(error);
				status.value = statusQuery.ERROR;
				Object.entries(error).forEach(([ key, value ]) => {
					data.data[key] = value;
				});
			});

		const currentStatus = computed(() => ({
			isPending: status.value === statusQuery.PENDING,
			isError: status.value === statusQuery.ERROR,
			isSuccess: status.value === statusQuery.SUCCESS
		}));
		const attributes = toRef(data, "data");

		return { data: attributes, status: currentStatus, errors };
	},
	methods: {
		editInfo() {
			return this.$emit("editCompany")
		}
	}
};
</script>
