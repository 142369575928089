<template>
	<div class="w-full max-w-lg mx-auto flex flex-col gap-4">
		<div>
			<h2 class="text-lg font-black">
				Основная информация о компании
			</h2>
		</div>
		<base-input
			v-model="field.companyName.value"
			type="text"
			label="Название компании"
			placeholder="Введите название"
			maxlength="50"
			:error="field.companyName.isError"
			:message="field.companyName.message"
			@blur="field.companyName.blur"
			@input="field.companyName.input"
		/>
		<base-input
			v-model="field.inn.value"
			type="text"
			label="ИНН компании"
			placeholder="Введите ИНН"
			maxlength="12"
			:error="field.inn.isError"
			:message="field.inn.message"
			@blur="field.inn.blur"
			@input="field.inn.input"
		/>
		<base-input
			v-model="field.yearFoundation.value"
			type="text"
			label="Год основания компании"
			placeholder="Введите год"
			maxlength="4"
			:error="field.yearFoundation.isError"
			:message="field.yearFoundation.message"
			@blur="field.yearFoundation.blur"
			@input="field.yearFoundation.input"
		/>

		<div class="-mt-4">
			<tool-tip class="left-8 top-[16px]">
				<div class="text-xs">
					Может содержать заглавные и строчные кириллические и латинские буквы и цифры. Должно содержать символ "."
				</div>
			</tool-tip>
			<base-input
				v-model="field.site.value"
				type="text"
				label="Сайт"
				placeholder="Введите ссылку"
				:error="field.site.isError"
				:message="field.site.message"
				@blur="field.site.blur"
				@input="field.site.input"
			/>
		</div>
		<drop-down-input
			v-model="field.cities.value"
			name="cities"
			label="В каких городах есть офис"
			placeholder="Выберите города"
			:more-option="true"
			more-option-title="Добавить свой город"
			:query="request.CITY"
			:error="field.cities.isError"
			:message="field.cities.message"
			:checkbox-option="true"
			:checkbox-active="checkboxActive"
			checkbox-option-text="Офиса нет, распределенная команда"
			@onСhoose="change"
			@selectCheckbox="selectCheckbox"
			@crateNewOption="createNewCity"
			@checkCheckbox="checkCheckbox"
		/>
		<drop-down-input
			v-model="field.format.value"
			name="format"
			label="Формат сотрудничества"
			placeholder="Можно выбрать несколько"
			:query="request.FORMAT"
			:error="field.format.isError"
			:message="field.format.message"
			@onСhoose="change"
		/>
		<base-button
			class="w-full"
			@click="toNextStep"
		>
			Дальше
		</base-button>
	</div>
</template>

<script>
import { submit } from "@/use/submit";
import { toRef } from "vue";
import { step2 } from "@/configForms/newCompany";
import BaseButton from "@/components/UI/BaseButton";
import DropDownInput from "@/components/DropDownInput/DropDownInput";
import BaseInput from "@/components/UI/BaseInput";
import ToolTip from "@/components/UI/ToolTip";
import { request } from "@/api/controllers";
import { REQUIRED_FIELD } from "@/handlers/constants";
import { checkInn } from "@/api";

export default {
	name: "Step2",
	components: { BaseInput, DropDownInput, BaseButton, ToolTip },
	emits: [ 'toNext' ],
	setup() {
		const init = submit({
			fields: step2
		});
		
		const form = toRef(init, "form");
		const status = toRef(init, "status");

		return { form, status };
	},
	
	data() {
		return {
			request: request,
			message: REQUIRED_FIELD,
			emailMessage: REQUIRED_FIELD,
			emailCorrected: true,
			checkboxActive: false,
			stop: false
		};
	},
	computed: {
		field() {
			return {
				...this.form.fields
			};
		}
	},
	methods: {
		toNextStep() {
			if (this.field.cities.value.length === 1) {
				const checkNewCities = this.field.cities.value.findIndex(i => i === 'no') !== -1;

				if (checkNewCities && !this.field.newCity.value) {
					this.field.cities.isError = true
					this.field.cities.message = this.message
					this.stop = true
				}
			}

			if (!this.form.valid || this.stop) {
				this.form.showErrors()
				return;
			}

			const checkBody = {
				data: {
					inn: this.field.inn.value
				}
			}

			checkInn(checkBody)
				.then(response => {
					this.$emit("toNext", this.form.fields)
					return response
				})
				.catch(e => {
					this.field.inn.isError = true
					this.field.inn.message = e.response.data.error.message
				})
		},
		change(list, name) {
			this.field[name].value = [ ...list ];
		},
		createNewCity(value) {
			const name = value.trim();
			if (name) {
				this.field.newCity.value = name;
				this.field.cities.isError = false;
				this.stop = false;
			} else {
				this.field.cities.isError = true
				this.field.cities.message = this.message
			}
		},
		checkCheckbox() {
			if (this.field.remote.value) {
				this.checkboxActive = false;
				this.field.remote.value = false;
			}
		},
		selectCheckbox() {
			if (!this.field.remote.value) {
				this.checkboxActive = true;
				this.change([ 'remote' ], 'cities');
				this.stop = false;
			} else {
				this.field.cities.value = [];
			}

			this.field.remote.value = !this.field.remote.value;
		}
	}
}
</script>

<style scoped>

</style>
