<template>
	<div>
		<h3
			v-if="title"
			class="text-lg font-medium mb-2"
		>
			{{ title }}
		</h3>
		<div class="flex flex-wrap gap-2">
			<tag-check
				v-for="variant in variants"
				:id="variant.id"
				:key="variant.id"
				:list-of="name"
				:name="variant.name"
				:color="color"
				:save="save"
				:filters="filters"
				@click="select"
				@remove="remove"
			/>
			<button
				v-if="resettable"
				:id="name + 'Reset'"
				:name="name"
				class="relative px-2 py-1 text-sm bg-light-gray"
				@click="reset"
			>
				Сбросить фильтр
			</button>
		</div>
	</div>
</template>

<script>
import TagCheck from "@/components/UI/TagCheck";

export default {
	name: "GroupTags",
	components: { TagCheck },
	props: {
		title: String,
		color: String,
		variants: {
			type: Array,
			required: true
		},
		name: String,
		save: {
			type: Boolean,
			default: false
		},
		resettable: {
			type: Boolean,
			default: false
		},
		filters: {
			type: Object
		}
	},
	methods: {
		select({ ...obj }) {
			this.$emit("select", { ...obj });
		},
		remove({ ...obj }) {
			this.$emit("remove", { ...obj });
		},
		reset() {
			this.$emit("reset");
		}
	}
};
</script>

<style scoped>

</style>