<template>
	<h2 class="text-xl">
		Ссылка на восстановление пароля больше не валидна
	</h2>
	<base-button @click="newLink">
		Получить новую ссылку
	</base-button>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
export default {
	name: "PasswordRecoveryErrInvalid",
	components: { BaseButton },
	methods: {
		newLink() {
			this.$router.push("/reset-password")
		}
	}
}
</script>

<style scoped>

</style>