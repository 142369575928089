import { required } from "@/helpers/validation";
import { REQUIRED_FIELD } from "@/handlers/constants";

export default () => {
	return {
		direction: {
			value: "",
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		comment: {
			value: "",
			validators: [
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		},
		action: {
			value: "",
			validators: []
		}
	};
}
