<template>
	<div class="mb-6 whitespace-pre-line data-styles">
		<slot />
	</div>
</template>

<script>
export default {
	name: "BaseDescription"
};
</script>

<style scoped>

</style>