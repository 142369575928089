<template>
	<registration-company-success v-if="success" />
	<registration-company-error v-if="error" />
</template>

<script>
import RegistrationCompanySuccess from "@/pages/RegistrationPage/FormRegCompanySteps/RegistrationCompanySuccess";
import RegistrationCompanyError from "@/pages/RegistrationPage/FormRegCompanySteps/RegistrationCompanyError";

export default {
	name: "FinishStep",
	components: { RegistrationCompanySuccess, RegistrationCompanyError },
	props: {
		error: {
			type: Boolean,
			default: false
		},
		success: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style scoped>

</style>