<template>
	<modal-template
		title="Заявка на новое мероприятие"
		@on-close="$emit('closeModal')"
	>
		<p
			v-if="status.isSuccess"
			class="text-center"
		>
			Заявка отправлена!
		</p>
		<form
			v-show="!status.isSuccess && !error"
			action=""
			class="w-full max-w-xs mx-auto flex flex-col gap-6"
			@submit.prevent="onSubmit"
		>
			<a
				class="underline text-center"
				target="_blank"
				href="https://docs.google.com/spreadsheets/d/1ApvfaQFMiDPhnLkRYbOTqyXbuUJxfimvGk6hn5Zm3iM/edit#gid=701454512"
			>
				Ознакомиться со свободными датами
			</a>
			<load-drop-down
				v-model="field.type.value"
				:query="request.EVENT_TYPE"
				name="type"
				class="w-full"
				label="Формат мероприятия"
				placeholder="Выберите формат"
				:error="field.type.isError"
				:message="field.type.message"
				@on-select="change"
			/>
			<base-datepicker
				v-model="field.date.value"
				label="Укажите дату и время начала"
				:error="field.date.isError"
				:message="field.date.message"
				:min-date="new Date()"
				prevent-min-max-navigation
				prevent-min
				centered
			/>
			<base-input
				v-model="field.description.value"
				class="w-full"
				label="Опишите вопросы, которые вы хотите поднять"
				type="textarea"
				placeholder="Вопросы"
				:error="field.description.isError"
				:message="field.description.message"
				@blur="field.description.blur"
				@input="field.description.input"
			/>
			<base-button type="submit">
				Отправить
			</base-button>
		</form>
		<div
			v-if="status.isError && error"
			class="flex flex-col justify-center"
		>
			<p class="w-full text-center">
				Выберите другую дату
			</p>
			<base-button
				class="mx-auto"
				@click="backToForm"
			>
				Другая дата
			</base-button>
		</div>
	</modal-template>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import BaseInput from "@/components/UI/BaseInput";
import BaseDatepicker from "@/components/UI/BaseDatepicker";
import ModalTemplate from "@/components/Modal/ModalTemplate";
import LoadDropDown from "@/components/UI/LoadDropDown";
import { submit } from "@/use/submit";
import { sendNewEvent } from "@/api/response";
import event from "@/configForms/event";
import { toRef } from "vue";
import { request } from "@/api/controllers";

export default {
	name: "NewEvent",
	components: { BaseDatepicker, BaseButton, BaseInput, ModalTemplate, LoadDropDown },
	setup() {
		const init = submit({
			submit: sendNewEvent,
			fields: event
		});
		
		const form = toRef(init, "form");
		const callback = toRef(init, "onSubmit");
		const status = toRef(init, "status");
		const error = toRef(init, "error");
		return { form, submit: callback, status, error };
	},
	data() {
		return {
			format: null,
			request: request
		};
	},
	computed: {
		field() {
			return { ...this.form.fields };
		}
	},
	methods: {
		onSubmit() {
			if (!this.form.valid) {
				this.form.showErrors();
				return;
			}
			this.submit();
			this.showContent = false;
		},
		change(value) {
			this.field.type.value = value;
		},
		backToForm() {
			this.status.isError = false;
			this.error = "";
		}
	}
};
</script>

<style scoped>

</style>