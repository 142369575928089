import { createStore } from "vuex";
import { KEYS, USER_ROLE } from "@/handlers/constants";

export default createStore({
	state() {
		return {
			token: null,
			user: {
				email: null,
				phone: null,
				name: null,
				lastName: null,
				post: null,
				companyId: null,
				companyName: null,
				confirmationLogo: null,
				userRole: null
			},
			company: null
		};
	},
	getters: {
		user(state) {
			return state.user;
		},
		company(state) {
			return state.company;
		},
		companyId(state) {
			return state.user.companyId;
		},
		companyName(state) {
			return state.user.companyName;
		},
		getUserRole(state) {
			return USER_ROLE[state.user.role];
		},
		isAuth(state) {
			return !!state.token
		},
		getToken(state) {
			return state.token
		},
		accessIsAllowed(state) {
			if (state.user.userRole === USER_ROLE.MODERATOR) return true;
			if (state.user.userRole === USER_ROLE.VALID_ADMIN) return true;
			if (state.user.userRole === USER_ROLE.EMPLOYEE && state.user.confirmationLogo) return true;

			return false;
		},
		isEmployee(state) {
			return state.user.userRole === USER_ROLE.EMPLOYEE;
		},
		isValidAdmin(state) {
			return state.user.userRole === USER_ROLE.VALID_ADMIN;
		}
	},
	mutations: {
		logout(state) {
			console.log('logout')
			localStorage.removeItem(KEYS.TOKEN);
			localStorage.removeItem(KEYS.USER);

			state.token = null;
			state.user = {
				id: null,
				email: null,
				phone: null,
				name: null,
				lastName: null,
				post: null,
				companyName: null,
				companyId: null,
				confirmationLogo: null,
				userRole: null
			}
			state.company = null
		},
		getToken(state, payload) {
			state.token = payload
		},
		getUser(state, { id, email, phone, name, lastName, post, company, userRole }) {
			state.user.id = id;
			state.user.email = email;
			state.user.phone = phone;
			state.user.name = name;
			state.user.lastName = lastName;
			state.user.post = post;
			state.user.companyId = company.id;
			state.user.companyName = company.name;
			state.user.confirmationLogo = company.confirmationLogo;
			state.user.userRole = userRole
		},
		getCompany(state, newValue) {
			state.company = { ...newValue }
		}
	}
});
