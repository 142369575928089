<template>
	<div
		class="relative inline-block focus:outline-none focus:ring sm:w-full"
	>
		<span
			class="absolute w-full h-full inset-0 translate-x-2 translate-y-2 bg-gray-50 shadow-md inset-0
			 transition-transform"
		/>
		
		<div class="w-full h-full relative flex flex-col gap-4 justify-between p-6 border-2 border-current sm:p-4">
			<div class="flex flex-col gap-4">
				<div
					class="h-0 w-full pb-[57%] overflow-hidden bg-no-repeat bg-cover bg-center"
					:style="{ 'background-image': 'url(' + img + ')' }"
				/>
				<h3
					class="font-bold uppercase cursor-pointer"
				>
					{{ dateTitle }}
				</h3>
				<h3
					class="font-bold uppercase cursor-pointer"
					@click="open"
				>
					{{ content.name }}
				</h3>
				<div class="flex flex-wrap gap-2">
					<div
						v-if="content.type"
						class="p-1 bg-lime text-sm"
					>
						{{ content.type.heading }}
					</div>
					<div
						v-for="direction of content.directions"
						class="p-1 bg-cyan text-sm"
					>
						{{ direction.heading }}
					</div>
				</div>
			</div>
			<div class="flex sm:justify-between w-full flex-wrap gap-4">
				<base-button @click="open">
					Подробнее
				</base-button>
			</div>
		</div>
	</div>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import { formattedDate } from "@/helpers/formattedDate";

export default {
	name: "CardVideo",
	components: { BaseButton },
	props: {
		content: {
			type: Object,
			required: true
		}
	},
	computed: {
		img() {
			if (this.content.preview) {
				return process.env.VUE_APP_API_HOST + this.content.preview;
			} else {
				return 'https://img.youtube.com/vi/' + this.content.idYoutube + '/maxresdefault.jpg';
			}
		},
		dateTitle() {
			if (this.content.date) return formattedDate(this.content.date)
		}
	},
	methods: {
		open() {
			this.$router.push(`/video/${this.content.id}`)
		},
		checkImg() {
			fetch('https://img.youtube.com/vi/' + this.content.idYoutube + '/maxresdefault.jpg')
				.then(() => 'https://img.youtube.com/vi/' + this.content.idYoutube + '/maxresdefault.jpg')
				.catch(() => 'https://img.youtube.com/vi/' + this.content.idYoutube + '/default.jpg')
		}
	}
};
</script>

<style scoped>

</style>