<template>
	<router-link
		class="hover:bg-white p-1 rounded-full border-2 border-transparent hover:border-black"
		:to="{ name: profileLink }"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke-width="2"
			stroke="currentColor"
			class="w-6 h-6"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998
						 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
			/>
		</svg>
	</router-link>
</template>

<script>
export default {
	name: "ProfileLink",
	data() {
		return {
			profileLink: this.$config.routes.profile.name
		}
	}
}
</script>

<style scoped>

</style>