import { isValidEmail, required } from "@/helpers/validation";
import { INVALID_EMAIL, REQUIRED_FIELD } from "@/handlers/constants";

export default {
	login: {
		value: "",
		validators: [
			{
				validator: isValidEmail,
				message: INVALID_EMAIL
			},
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	password: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	}
};