<template>
	<slot
		v-if="success"
		name="success"
	/>
	<slot
		v-if="!success && !error"
		name="content"
	/>
	<slot
		v-if="error"
		name="error"
	/>
</template>

<script>
export default {
	name: "QueryWrapper",
	props: {
		success: {
			type: Boolean,
			required: true
		},
		error: {
			type: Boolean,
			required: true
		},
		showError: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style scoped>

</style>