<template>
	<page-loading
		:status="status"
		:errors="errors?.empty"
		:message="errors?.empty"
	>
		<base-title v-if="data.title">
			{{ data.title }}
		</base-title>
		<base-description
			v-if="data.subTitle"
			v-html="markdownToHtml"
		/>
		<div class="grid gap-6 grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 mb-6 mt-6">
			<docs-card
				v-for="doc of data.list"
				v-if="data.list"
				:key="doc"
				:content="doc"
			/>
		</div>
	</page-loading>
</template>

<script>
import BaseTitle from "@/components/UI/BaseTitle";
import BaseDescription from "@/components/UI/BaseDescription";
import DocsCard from "@/pages/DocsPage/DocsCard";
import PageLoading from "@/components/PageLoading/PageLoading";
import { loadData } from "@/use/load";
import { docsPage } from "@/api";
import { mainHandler } from "@/handlers";
import { toRef } from "vue";
import { marked } from "marked";

export default {
	name: "DocsPage",
	components: { BaseTitle, DocsCard, BaseDescription, PageLoading },
	setup() {
		const { data: response, status } = loadData({ query: docsPage, handler: mainHandler });
		const data = toRef(response, "data");
		const errors = toRef(response, "errors");
		
		return {
			data,
			status,
			errors
		};
	},
	computed: {
		markdownToHtml() {
			return marked(this.data.subTitle);
		}
	}
};
</script>

<style scoped>

</style>