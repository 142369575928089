<template>
	<base-input
		ref="input"
		v-model="value"
		name="email"
		type="text"
		:placeholder="placeholder"
		:error="error"
		:label="label"
		:message="message"
		@blur="$emit('blur')"
		@input="$emit('input')"
	/>
</template>

<script>
import BaseInput from "@/components/UI/BaseInput";

export default {
	name: "InputEmail",
	components: { BaseInput },
	props: {
		modelValue: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: "text"
		},
		message: {
			type: String,
			default: () => {
			}
		},
		error: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			options: {
				clearMaskOnLostFocus: true,
				placeholder: " ",
				showMaskOnHover: false,
				showMaskOnFocus: false,
				showMaskOnInput: false,
				alias: "email"
			}
		};
	},
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("update:modelValue", value);
			}
		}
	},
	mounted() {
		// eslint-disable-next-line global-require,no-unused-vars
		//     const inputmask = require("inputmask");
		//     const selector = this.$refs.input.$el.querySelector("input");
		//     if (selector) {
		//         this.bindMask(selector);
		//     }
	},
	methods: {
		bindMask(inputElement) {
			// eslint-disable-next-line no-undef
			Inputmask(this.options).mask(inputElement);
		}
	}
};
</script>

<style scoped></style>
