<template>
	<div
		class="p-1 text-xs"
		:class="{
			'bg-purple': status === statusGroup.SUSPENDED,
			'bg-lime': status === statusGroup.ACTIVE,
			'bg-cyan': status === statusGroup.NOTSTARTED,
			'bg-orange': status === statusGroup.COMPLETED,
		}"
	>
		{{ status }}
	</div>
</template>

<script>
import { statusGroup } from "@/handlers/constants";

export default {
	name: "GroupStatus",
	props: {
		status: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			statusGroup: statusGroup
		};
	}
};
</script>

<style scoped>

</style>