<template>
	<query-wrapper
		:pending="true"
		:error="false"
		:success="success"
	>
		<template #content>
			<p class="text-center w-full max-w-md mx-auto">
				Для восстановления пароля введите e-mail, указанный при регистрации.
				Ссылку на восстановление мы вышлем в письме.
			</p>
			<form
				action=""
				class="w-full max-w-[412px] mx-auto flex flex-col gap-6"
				@submit.prevent="onSubmit"
			>
				<div class="-mt-4">
					<tool-tip class="left-10 top-[15px]">
						<div class="text-xs">
							E-mail должен состоять только из латинских символов и цифр, содержать специальный символ "@". После символа "@" должна быть как минимум одна "."
						</div>
					</tool-tip>
					<input-email
						v-model="field.email.value"
						label="E-mail"
						placeholder="Введите логин"
						:error="field.email.isError"
						:message="field.email.message"
						@input="field.email.input"
						@blur="field.email.blur"
					/>
				</div>
				<base-button
					type="submit"
					:disabled="!isChanged"
				>
					Отправить
				</base-button>
			</form>
		</template>
		<template #success>
			<p class="max-w-3xl mx-auto text-center text-2xl">
				Мы отправили вам письмо на указанный адрес электронной почты с инструкцией по восстановлению пароля.
				В этом письме вы найдете ссылку на страницу восстановления пароля, которую нужно перейти и следовать дальнейшим инструкциям.
				<br>
				Проверьте папку "Спам", если не можете найти письмо в основной папке "Входящие".
			</p>
		</template>
	</query-wrapper>
</template>

<script>
import InputEmail from "@/components/UI/InputEmail";
import BaseButton from "@/components/UI/BaseButton";
import QueryWrapper from "@/components/layouts/QueryWrapper";
import ToolTip from "@/components/UI/ToolTip";
import { submit } from "@/use/submit";
import { toRef } from "vue";
import resetPasswordEmail from "@/configForms/resetPasswordEmail";
import { forgotPassword } from "@/api";

export default {
	name: "PasswordRecoveryEmail",
	components: { InputEmail, BaseButton, QueryWrapper, ToolTip },
	setup() {
		const init = submit({
			fields: resetPasswordEmail()
		});
		
		const form = toRef(init, "form");
		const status = toRef(init, "status");
		return { form, status };
	},
	data() {
		return {
			success: false
		}
	},
	
	computed: {
		field() {
			return { ...this.form.fields };
		},
		isChanged() {
			return Object.values(this.form.fields).some(item => item.changed);
		}
	},
	
	methods: {
		onSubmit() {
			if (!this.isChanged) return;

			if (!this.form.valid) {
				this.form.showErrors();
				return;
			}
			const data = {
				email: this.field.email.value
			}
			forgotPassword(data).then(response => {
				this.success = true;
				return response
			}).catch(err => {
				console.log(err)
			})
		}
	}
};
</script>

<style scoped>

</style>