import { isValidEmail, isValidString, required } from "@/helpers/validation";
import { INVALID_EMAIL, INVALID_STRING, REQUIRED_FIELD } from "@/handlers/constants";

export default () => {
	return {
		email: {
			value: "",
			validators: [
				{
					validator: isValidEmail,
					message: INVALID_EMAIL
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]

		},
		name: {
			value: "",
			validators: [
				{
					validator: isValidString,
					message: INVALID_STRING
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]

		},
		lastName: {
			value: "",
			validators: [
				{
					validator: isValidString,
					message: INVALID_STRING
				},
				{
					validator: required,
					message: REQUIRED_FIELD
				}
			]
		}
	}
}