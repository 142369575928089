<template>
	<button
		:id="id"
		:name="name"
		class="relative px-2 py-1"
		:class="bg"
		:disabled="disabled"
		@click="click"
	>
		<span
			class="absolute z-1 inset-0 transition-transform  bg-white"
			:class="[ isActive ? 'opacity-0' : 'opacity-60' ]"
		/>
		<span
			class="w-full relative inline-block text-sm"
		>
			{{ name }}
		</span>
	</button>
</template>

<script>
import { filterList, statusGroup } from "@/handlers/constants";

export default {
	name: "TagCheck",
	props: {
		id: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		color: {
			type: String,
			default: "orange"
		},
		removed: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		save: {
			type: Boolean,
			default: false
		},
		listOf: String,
		filters: {
			type: Object
		}
	},
	data() {
		return {
			selected: false
		}
	},
	computed: {
		bg() {
			if (this.listOf === filterList.GROUP_STATUS) {
				if (this.name === statusGroup.SUSPENDED) return 'bg-purple'
				if (this.name === statusGroup.ACTIVE) return 'bg-lime'
				if (this.name === statusGroup.NOTSTARTED) return 'bg-cyan'
				if (this.name === statusGroup.COMPLETED) return 'bg-orange'
			}

			return `bg-${this.color}`
		},
		isActive() {
			return this.filters[this.listOf].includes(this.id)
		}
	},
	methods: {
		click() {
			if (this.isActive) {
				return this.remove()
			}

			return this.select();
		},
		select() {
			this.$emit('click', { id: this.id, name: this.name, color: this.color, listOf: this.listOf, selected: this.selected })
		},
		remove() {
			this.$emit('remove', { id: this.id, name: this.name, color: this.color, listOf: this.listOf, selected: this.selected })
		}
	}
};
</script>

<style scoped></style>
