<template>
	<div class="flex flex-col min-h-[80vh] p-6 gap-6 justify-center text-center">
		<a
			href="https://arda.digital/"
			target="_blank"
			class="text-center"
		>
			<img
				src="@/assets/black_ARDA_logo.svg"
				alt="ARDA"
				class="max-w-xs w-full mx-auto"
			>
		</a>
		<div class="max-w-3xl mx-auto">
			<h2 class="text-2xl font-semibold mb-3">
				Спасибо за регистрацию!
			</h2>
			Вы сможете авторизоваться, как только вашу учетную запись активирует администратор вашей компании.
		</div>
	</div>
</template>

<script>
export default {
	name: "RegistrationEmployeeSuccess"
};
</script>

<style scoped>

</style>