<template>
	<h2 class="text-xl">
		Ошибка сервера - попробуйте позже
	</h2>
	<base-button @click="$emit('newAttempt')">
		Попробовать снова
	</base-button>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
export default {
	name: "PasswordRecoveryErrServer",
	components: { BaseButton }
}
</script>

<style scoped>

</style>