<template>
	<div class="fixed overflow-scroll h-full z-30 left-0 top-0 pt-16 px-6 pb-6 w-full min-h-screen bg-white">
		<div class="max-w-[1920px] mx-auto">
			<div v-show="!status.isSuccess">
				<base-title>Редактировать карточку компании</base-title>
				<form
					class="grid grid-cols-2 sm:grid-cols-1 grid-auto-row items-start gap-4 mb-6"
					@submit.prevent="onSubmit"
				>
					<base-input
						v-model="field.name.value"
						placeholder="Введите название"
						label="Название"
						maxlength="50"
						:error="field.name.isError"
						:message="field.name.message"
						@blur="field.name.blur"
						@input="field.name.input"
					/>
					<base-input
						v-model="field.inn.value"
						placeholder="Введите ИНН"
						label="ИНН"
						maxlength="12"
						:error="field.inn.isError"
						:message="field.inn.message"
						@blur="field.inn.blur"
						@input="field.inn.input"
					/>
					<div class="-mt-4">
						<tool-tip class="left-8 top-[16px]">
							<div class="text-xs">
								Может содержать заглавные и строчные кириллические и латинские буквы и цифры. Должно содержать символ "."
							</div>
						</tool-tip>
						<base-input
							v-model="field.site.value"
							placeholder="Введите ссылку"
							label="Сайт"
							:error="field.site.isError"
							:message="field.site.message"
							@blur="field.site.blur"
							@input="field.site.input"
						/>
					</div>
					<base-input
						v-model="field.yearFoundation.value"
						placeholder="Введите год"
						label="Год основания"
						maxlength="4"
						:error="field.yearFoundation.isError"
						:message="field.yearFoundation.message"
						@blur="field.yearFoundation.blur"
						@input="field.yearFoundation.input"
					/>
					<drop-down-input
						v-model="field.format.value"
						:selected-items="field.format.value"
						name="format"
						label="Формат сотрудничества"
						placeholder="Можно выбрать несколько"
						:query="request.FORMAT"
						:error="field.format.isError"
						:message="field.format.message"
						@onСhoose="change"
						@crateNewOption="createNewCity"
					/>
					<drop-down-input
						v-model="field.cities.value"
						:selected-items="field.cities.value"
						name="cities"
						label="Город"
						placeholder="Можно выбрать несколько"
						:more-option="true"
						searchable
						more-option-title="Добавить свой город"
						:query="request.CITY"
						:error="field.cities.isError"
						:message="field.cities.message"
						:checkbox-option="true"
						:checkbox-active="checkboxActive"
						checkbox-option-text="Офиса нет, распределенная команда"
						@onСhoose="changeCity"
						@selectCheckbox="selectCheckbox"
						@crateNewOption="createNewCity"
						@checkCheckbox="checkCheckbox"
					/>
					<load-drop-down
						v-model="field.workers.value"
						:query="request.WORKERS"
						name="workers"
						label="Количество сотрудников"
						placeholder="Выберите количество сотрудников"
						:error="field.workers.isError"
						:message="field.workers.message"
						@on-select="changeWorkers"
					/>
					<load-drop-down
						v-model="field.specializations.value"
						:query="request.SPECIALIZATION"
						name="specializations"
						label="Основная специализация"
						placeholder="Выберите специализацию"
						:error="field.specializations.isError"
						:message="field.specializations.message"
						@on-select="changeSpecializations"
					/>
					<drop-down-input
						v-model="field.services.value"
						:selected-items="field.services.value"
						name="services"
						label="Услуги"
						placeholder="Выберите услуги"
						:query="request.SERVICE"
						:error="field.services.isError"
						:message="field.services.message"
						@onСhoose="change"
					/>
					<drop-down-input
						v-model="field.channels.value"
						:selected-items="field.channels.value"
						name="channels"
						label="Каналы и технологии"
						placeholder="Выберите услуги"
						:query="request.CHANNEL"
						:error="field.channels.isError"
						:message="field.channels.message"
						@onСhoose="change"
					/>
					<div class="flex gap-6 sm:flex-col">
						<base-button
							type="submit"
							:disabled="disable"
						>
							Сохранить
						</base-button>
						<button @click="$emit('close')">
							Отмена
						</button>
					</div>
				</form>
			</div>
			<div
				v-if="status.isSuccess"
				class="mt-8 mb-3 w-full text-center flex flex-col justify-center gap-6"
			>
				<p class="font-medium text-3xl">
					Сохраненные вами данные отправлены на модерацию. <br>
					Изменения вступят в силу как только будут проверены
				</p>
				<base-button
					class="max-w-sm mx-auto"
					@click="$emit('close')"
				>
					Понятно
				</base-button>
			</div>
		</div>
	</div>
</template>

<script>
import BaseInput from "@/components/UI/BaseInput";
import LoadDropDown from "@/components/UI/LoadDropDown";
import DropDownInput from "@/components/DropDownInput/DropDownInput";
import { request } from "@/api/controllers";
import BaseButton from "@/components/UI/BaseButton";
import { submit } from "@/use/submit";
import editCompany from "@/configForms/editCompany";
import { toRef } from "vue";
import BaseTitle from "@/components/UI/BaseTitle";
import ToolTip from "@/components/UI/ToolTip";
import { changeInfoCompany } from "@/api/response";
import store from "@/store";

export default {
	name: "EditCompany",
	components: { BaseTitle, BaseButton, BaseInput, LoadDropDown, DropDownInput, ToolTip },
	emits: [ 'cancel', 'close' ],
	setup() {
		const init = submit({
			submit: changeInfoCompany,
			fields: editCompany()
		});

		const form = toRef(init, "form");
		const status = toRef(init, "status");
		const callback = toRef(init, "onSubmit");

		return { form, submit: callback, status };
	},
	data() {
		return {
			request: request,
			changed: false,
			checkboxActive: this.form.fields.remote.value,
			stop: false
		}
	},
	computed: {
		field() {
			return { ...this.form.fields };
		},
		isChanged() {
			return Object.values(this.form.fields).some(item => item.changed);
		},
		disable() {
			return !(this.isChanged || this.changed);
		}
	},
	methods: {
		onSubmit() {
			if (this.field.cities.value.length === 1) {
				const checkNewCities = this.field.cities.value.findIndex(i => i === 'no') !== -1;

				if (checkNewCities && !this.field.newCity.value) {
					this.field.cities.isError = true
					this.field.cities.message = this.message
					this.stop = true
				}
			}

			if (!this.form.valid || this.stop) {
				this.form.showErrors();
				return;
			}
			this.submit();
		},
		changeCity(list) {
			if (!this.field.cities.value.includes('no') && list.includes('no') && !this.changed) {
				this.changed = false
				this.field.cities.value = [ ...list ];
			} else if (this.field.cities.value - list.length >= 2) {
				this.changed = true
				this.field.cities.value = [ ...list ];
			} else {
				this.changed = true
				this.field.cities.value = [ ...list ];
			}
		},
		change(list, name) {
			this.changed = true
			this.field[name].value = [ ...list ];
		},
		createNewCity(value) {
			this.changed = true
			const name = value.trim();
			if (name) {
				this.field.newCity.value = name;
				this.field.cities.isError = false;
				this.stop = false;
			} else {
				this.field.cities.isError = true
				this.field.cities.message = this.message
			}

		},
		changeWorkers(value) {
			console.log(store.getters.company.workers[0])
			console.log(value)
			console.log(this.field.workers.value)
			if (store.getters.company.workers[0] === value) {
				this.changed = false
			} else {
				this.changed = true
			}

			this.field.workers.value = value;
		},
		changeSpecializations(value) {
			this.changed = true
			this.field.specializations.value = value;
		},
		checkCheckbox() {
			if (this.field.remote.value) {
				this.checkboxActive = false;
				this.field.remote.value = false;
			}
		},
		selectCheckbox() {
			if (!this.field.remote.value) {
				this.checkboxActive = true;
				this.change([ 'remote' ], 'cities');
				this.stop = false;
			} else {
				this.field.cities.value = [];
			}

			this.field.remote.value = !this.field.remote.value;
		}
	}
}
</script>
