<template>
	<div class="flex flex-wrap relative mb-1.5">
		<label
			v-if="label"
			for="email"
			class="w-full text-xs font-thin mb-1"
		>
			{{ label }}
		</label>
		<div class="relative block group w-full">
			<span
				class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0 group-focus:translate-y-0 group-focus:translate-x-0"
			/>
			<vue-date-picker
				v-model="value"
				class="picker-custom w-full bg-transparent relative inline-block font-bold text-sm tracking-widest text-black border-2 border-current"
				:format="format"
				month-name-format="long"
				:teleport-center="centered"
				:min-date="minDate"
				locale="ru-RU"
				auto-apply
				:placeholder="placeholder"
				prevent-min-max-navigation=""
			/>
		</div>
		<span
			v-if="error"
			class="absolute -bottom-6 text-red inline-block text-xs font-thin"
		>
			{{ message }}
		</span>
	</div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import { REQUIRED_FIELD } from "@/handlers/constants";
// TODO https://vue3datepicker.com/slots/content/#marker-tooltip для выделения дней

export default {
	name: "BaseDatepicker",
	components: { VueDatePicker },
	props: {
		modelValue: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: false
		},
		type: {
			type: String,
			default: "text"
		},
		error: {
			type: Boolean,
			default: false
		},
		centered: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: "Выберите дату"
		},
		message: {
			type: String,
			default: REQUIRED_FIELD
		},
		minDate: {
			type: Date,
			required: false
		},
		preventMin: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			picked: null
		};
	},
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("update:modelValue", value);
			}
		}
	},
	methods: {
		format(date) {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			const hours = date.getHours();
			const min = date.getMinutes()
			
			return `${String(day).padStart(2, "0")}.${String(month).padStart(2, "0")}.${year} ${String(hours).padStart(2, "0")}:${String(min).padStart(2, "0")}`;
		}
	}
};
</script>

<style scoped>

</style>