import { reactive, computed, ref } from "vue";
import { statusQuery } from "@/handlers/constants";

export function filter(submit) {
	let status = reactive({ value: statusQuery.SUCCESS });
	let error = ref('');

	const onSubmit = filters => {
		status.value = statusQuery.PENDING
		return submit(filters).then(({ data }) => {
			if (!data.success) return Promise.reject(data.errors)
			status.value = statusQuery.SUCCESS
			return data.data
		}).catch(e => {
			console.log(e)
			status.value = statusQuery.ERROR
			error.value = e;
		})
	}

	const currentStatus = computed(() => ({
		isPending: status.value === statusQuery.PENDING,
		isError: status.value === statusQuery.ERROR,
		isSuccess: status.value === statusQuery.SUCCESS
	}));

	return { onSubmit, status: currentStatus, error }
}
