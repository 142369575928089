<template>
	<div class="flex flex-col min-h-[80vh] p-6 gap-6 justify-center text-center">
		<a
			href="https://arda.digital/"
			target="_blank"
			class="text-center"
		>
			<img
				src="@/assets/black_ARDA_logo.svg"
				alt="ARDA"
				class="max-w-xs w-full mx-auto"
			>
		</a>
		<div class="max-w-3xl mx-auto">
			<h2 class="text-2xl font-semibold mb-3">
				{{ message }}
			</h2>
		</div>

		<div class="max-w-sm mx-auto">
			<base-button @click="$emit('click')">
				Вернуться к форме
			</base-button>
		</div>
	</div>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
export default {
	name: "RegistrationEmployeeError",
	components: { BaseButton },
	props: {
		message: {
			type: String
		}
	}
};
</script>

<style scoped>

</style>