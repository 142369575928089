import { required } from "@/helpers/validation";
import { REQUIRED_FIELD } from "@/handlers/constants";

export default {
	type: {
		value: null,
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	date: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	},
	description: {
		value: "",
		validators: [
			{
				validator: required,
				message: REQUIRED_FIELD
			}
		]
	}
};
