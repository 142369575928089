import { createApp } from "vue";
import App from "./App.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "./index.css";
import { VueMasonryPlugin } from "vue-masonry";
import router from "@/router";
import plugins from "@/plugins";
import store from "@/store";

createApp(App)
	.use(store)
	.use(router)
	.use(plugins)
	.use(VueMasonryPlugin)
	.mount("#app")
