<template>
	<query-wrapper
		:pending="true"
		:error="error"
		:success="success"
	>
		<template #content>
			<form
				class="w-full max-w-[412px] mx-auto w-full flex flex-col gap-6"
				@submit.prevent="onSubmit"
			>
				<create-password
					v-model="password"
					@setPass="setPass"
				/>
				
				<base-button
					:disabled="disabled"
					type="submit"
				>
					Сохранить
				</base-button>
			</form>
		</template>
		<template #success>
			<password-recovery-success />
		</template>
		<template #error>
			<password-recovery-err-server
				v-if="errorSever"
				@new-attempt="newAttempt"
			/>
			<password-recovery-err-invalid v-if="errorInvalid" />
		</template>
	</query-wrapper>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton";
import QueryWrapper from "@/components/layouts/QueryWrapper";
import CreatePassword from "@/components/CreatePassword";
import { resetPassword } from "@/api";
import router from "@/router";
import PasswordRecoveryErrServer from "@/pages/AuthPage/PasswordRecoveryErrServer";
import PasswordRecoveryErrInvalid from "@/pages/AuthPage/PasswordRecoveryErrInvalid";
import PasswordRecoverySuccess from "@/pages/AuthPage/PasswordRecoverySuccess";
import store from "@/store";

export default {
	name: "PasswordRecovery",
	components: { PasswordRecoverySuccess, PasswordRecoveryErrInvalid, PasswordRecoveryErrServer, CreatePassword, BaseButton, QueryWrapper },
	data() {
		return {
			password: '',
			disabled: true,
			success: false,
			error: false,
			errorSever: false,
			errorInvalid: false
		}
	},
	methods: {
		setPass(value) {
			if (!value) {
				this.disabled = true
				this.password = ''
				return
			}
			this.password = value
			this.disabled = false
		},
		onSubmit() {
			const { code } = router.currentRoute.value.query;

			const data = {
				code: code,
				password: this.password,
				passwordConfirmation: this.password
			}

			resetPassword(data).then(() => {
				localStorage.removeItem("token")
				store.commit('getToken', null)
			}).then(() => {
				this.success = true
			}).catch(err => {
				this.error = true
				this.errorSever = true
				if (err.request.status === 400) {
					this.errorSever = false
					this.errorInvalid = true
				}
			})
		},
		newAttempt() {
			this.error = false
		}
	}
};
</script>

<style scoped>

</style>