<template>
	<div
		class="fixed backdrop-blur-sm left-0 top-0 z-50 bg-background w-full
        h-screen overflow-auto flex"
		@mousedown.self="$emit('onClose')"
	>
		<div
			class="max-w-3xl w-[100%] min-h-min relative p-2 block m-auto overflow-hidden flex flex-col"
		>
			<close-modal @click="$emit('onClose')" />
			<span
				class="absolute inset-2 -translate-x-2 -translate-y-2 border-2 border-current z-50 pointer-events-none"
			/>
			<div
				class="relative inline-block w-full h-[100%] overflow-y-auto bg-white flex justify-center"
			>
				<div class="flex flex-col justify-center gap-4 mb-2 mt-4 p-6">
					<h3
						v-if="title"
						class="font-medium text-lg text-center mb-2"
					>
						{{ title }}
					</h3>
					<slot />
					<div
						v-if="buttonText"
						class="flex justify-center"
					>
						<base-button @click="$emit('onClick')">
							{{ buttonText }}
						</base-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CloseModal from "@/components/Modal/CloseModal";
import BaseButton from "@/components/UI/BaseButton";

export default {
	name: "ModalTemplate",
	components: { CloseModal, BaseButton },
	props: {
		title: {
			type: String
		},
		buttonText: {
			type: String,
			required: false
		}
	},
	emits: [ "onClose", "onClick" ]
};
</script>

<style scoped></style>
