import axiosInstance from "@/plugins/axios";
import { API_REQUEST } from "@/api/controllers";

export const axiosGet = async request => {
	return axiosInstance.get(API_REQUEST[request]);
};

export const axiosGetId = async (request, id) => {
	return axiosInstance.get(`${API_REQUEST[request]}/${id}`);
};

export const axiosPost = async (request, data) => {
	return axiosInstance.post(API_REQUEST[request], data);
};

export function pipe(...functions) {
	return input => functions.reduce((cumulativeOutput, fn) => {
		return fn(cumulativeOutput);
	}, input);
}

export function hasPlainText(html) {
	if (!html) {
		return false;
	}

	const plainText = convertHtmlToPlainText(html);
	return plainText.length > 0;
}

function convertHtmlToPlainText(html) {
	return pipe(removeHtmlTags, normalizeWhitespace)(html);
}

function removeHtmlTags(html) {
	return html.replace(/<[^>]*>/g, "");
}

function normalizeWhitespace(string) {
	return string.replace(/\s+/, " ").trim();
}
